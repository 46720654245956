import React from "react";
import InfoMessage from "../stories/InfoMessage";

const NotFound = () => {
  return (
    <div className="bg-light-gray pt-2 pb-4">
      <InfoMessage
        size="full"
        ctaLink="/"
        title="404 | Page not found"
        description="It looks like the page you're looking for doesn't exist."
        ctaTitle="Back to Home"
      />
    </div>
  );
};

export default NotFound;
