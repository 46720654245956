export const IMAGE_MENU = [
  {
    name: "From camera",
    cta: "camera",
    image: "/assets/images/icons/cameraIcon.svg",
    accept: "image/*",
    capture: "camera",
  },
  {
    name: "From gallery",
    cta: "gallery",
    image: "/assets/images/icons/galleryIcon.svg",
    accept: "image/*",
    capture: null,
  },
  {
    name: "From files",
    cta: "files",
    image: "/assets/images/icons/fileIcon.svg",
    accept: "image/*, .pdf",
    capture: null,
  },
];

export const FILE_MENU = [
  {
    name: "From files",
    cta: "files-word",
    image: "/assets/images/icons/fileIcon.svg",
    accept: ".doc,.docx,.pdf",
  },
];

export const FILE_TYPE_MENU = [
  {
    name: "Add a PDF file",
    cta: "pdf",
    image: "/assets/images/icons/pdfIcon.svg",
  },
  {
    name: "Add a Word file",
    cta: "word",
    image: "/assets/images/icons/wordIcon.svg",
  },
  {
    name: "Add / Capture image(s)",
    cta: "image",
    image: "/assets/images/icons/imgIcon.svg",
  },
];

export const FILE_MODIFY_MENU = [
  {
    name: "Rename File",
    cta: "rename",
    image: "/assets/images/icons/pencil.svg",
  },
  {
    name: "Save as Draft",
    cta: "draft",
    image: "/assets/images/icons/save.svg",
  },
  {
    name: "Discard File",
    cta: "discard",
    image: "/assets/images/icons/discardIcon.svg",
  },
];

export const FILE_DELETE_MENU = [
  {
    name: "Discard File",
    cta: "discard",
    image: "/assets/images/icons/discardIcon.svg",
  },
];
