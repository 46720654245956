import React, { useEffect, useState } from "react";
import { ErrorMessage, useField } from "formik";
import OtpInput from "react-otp-input";
import classNames from "classnames";

const Otp = ({
  disable,
  errmsg,
  clearOtpHandler,
  setClearOtp,
  setErrmsg,
  ...props
}) => {
  const [field, meta, { setValue, setTouched }] = useField(props);
  const [otpValue, setOtpValue] = useState({ otp: "" });
  const handleChange = (otp) => {
    setOtpValue({ otp });
    setTouched(false);
    setValue(otp);
  };

  useEffect(() => {
    if (clearOtpHandler) {
      setOtpValue({ otp: "" });
      setClearOtp(false);
      setTouched(false);
      setErrmsg(false);
    }
  }, [clearOtpHandler]);

  const BorderWidth = (meta.error && meta.touched) || errmsg ? "1px" : "0px";
  const BorderColor = (meta.error && meta.touched) || errmsg ? "#FDA29B" : null;
  const BorderColor2 =
    (meta.error && meta.touched) || errmsg
      ? "#FDA29B"
      : "rgba(64, 64, 64, 0.2)";
  const BackgroundColor =
    (meta.error && meta.touched) || errmsg ? "white" : "#f3f5f9";
  const ShowErr =
    meta.error && meta.touched ? "hidden" : errmsg ? "block" : "hidden";
  return (
    <>
      <div className=" flex flex-col items-center justify-center ">
        <div>
          <OtpInput
            containerStyle="bg-[#f3f5f9]"
            inputStyle={{
              fontFamily: "HK Grotesk",
              width: "4.063rem",
              height: "4.063rem",
              margin: "0.1rem",
              backgroundColor: BackgroundColor,
              fontSize: "25px",
              lineHeight: "2.25rem",
              borderRadius: "1.2rem",
              borderWidth: "1px",
              borderColor: BorderColor2,
              borderStyle: "solid",
              fontWeight: "700",
            }}
            focusStyle={{
              backgroundColor: "#fff",
              outline: "none",
              borderWidth: BorderWidth,
              opacity: "1",
              fontWeight: "700",
              filter: "drop-shadow(0 12px 19px rgb(0 0 0 / 0.09))",
              caretColor: "#5468FF",
              fontSize: "25px",
              borderColor: BorderColor,
            }}
            value={otpValue?.otp}
            onChange={handleChange}
            numInputs={4}
            separator={<span>&nbsp; &nbsp; </span>}
            onBlur={() => setTouched(true)}
            isDisabled={!!disable}
            isInputNum={true}
          />
          <div className="mt-0 ml-1">
            <ErrorMessage
              name={props.name}
              component="span"
              className="text-tomato text-xs font-normal capitalize"
            ></ErrorMessage>
          </div>
          <div
            className={classNames(
              "text-tomato mt-1.5 ml-1 text-xs font-normal",
              ShowErr
            )}
          >
            <p>Please enter a valid otp</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
