import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import Button from "../buttons/Button";
const PopUp = ({
  title,
  description,
  type,
  primaryCta,
  primaryOnClick,
  secondaryCta,
  secondaryOnClick,
  dismissButton,
  dismissButtonOnClick,
  buttonBackgroundColor,
  buttonsLeftAligned,
  centered,
  icon,
  open,
  primaryCtaLoading,
  ...rest
}) => {
  const containerStyle = !buttonBackgroundColor ? "px-6 pt-6 pb-6 sm:p-6" : "";
  const innerContainerStyle = buttonBackgroundColor
    ? "bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
    : "";
  const buttonAlignmentStyle =
    !buttonsLeftAligned && !centered
      ? "sm:flex-row-reverse"
      : centered && secondaryCta
      ? "mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
      : centered && !secondaryCta
      ? ""
      : "sm:ml-10 sm:pl-4";
  const buttonContainerStyle = buttonBackgroundColor
    ? `${buttonBackgroundColor} px-4 py-3 sm:px-6`
    : !centered
    ? "mt-5 sm:mt-4"
    : centered && !secondaryCta
    ? "mt-5 sm:mt-6"
    : "";
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        open={open}
        onClose={dismissButtonOnClick}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                "bg-light-gray inline-block w-full transform overflow-hidden rounded-2xl text-left align-bottom shadow-sm transition-all sm:my-8 sm:max-w-lg sm:align-middle",
                containerStyle
              )}
            >
              {dismissButton ? (
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    onClick={dismissButtonOnClick}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className={innerContainerStyle}>
                <div
                  className={classNames(
                    !centered ? "sm:flex sm:items-start" : ""
                  )}
                >
                  <div
                    className={classNames(
                      "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full  ",
                      !centered ? "sm:mx-0 sm:h-10 sm:w-10" : "",
                      type === "warning" ? "bg-red-100" : "bg-green-100"
                    )}
                  >
                    {icon?.type && (
                      <icon.type
                        className={classNames(
                          "h-6 w-6 ",
                          type === "warning" ? "text-red-600" : "text-green-600"
                        )}
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div
                    className={classNames(
                      "mt-3 text-center ",
                      !centered ? "sm:mt-0 sm:ml-4 sm:text-left" : ""
                    )}
                  >
                    <Dialog.Title
                      as="h3"
                      className="font-grotesk text-xl font-bold leading-6"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="font-grotesk text-lg font-medium text-neutral-700">
                        {description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {primaryCta || secondaryCta ? (
                <div
                  className={classNames(
                    "flex flex-col justify-between space-y-2 sm:flex",
                    buttonContainerStyle,
                    buttonAlignmentStyle
                  )}
                >
                  {primaryCta ? (
                    <Button
                      className="inline-flex w-full justify-center rounded-md border border-transparent  bg-neutral-800 px-4 py-2 text-base font-medium text-white shadow-none hover:bg-neutral-700 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={primaryOnClick}
                      loading={primaryCtaLoading}
                      size="lg"
                    >
                      <span>{primaryCta}</span>
                    </Button>
                  ) : (
                    ""
                  )}
                  {secondaryCta ? (
                    <Button
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700  shadow-none hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={secondaryOnClick}
                      size="lg"
                      buttonStyle="white"
                    >
                      <span>{secondaryCta}</span>
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
PopUp.defaultProps = {
  dismissButton: false,
  buttonsLeftAligned: false,
  centered: false,
};
PopUp.propTypes = {
  /**
   *Title
   */
  title: PropTypes.string,
  /**
   * Description
   */
  description: PropTypes.string,
  /**
   * Type
   */
  type: PropTypes.oneOf(["success", "warning"]),
  /**
   * Primary CTA
   */
  primaryCta: PropTypes.string,
  /**
   * Primary Button OnClick Action
   */
  primaryOnClick: PropTypes.func,
  /**
   * Secondary CTA
   */
  secondaryCta: PropTypes.string,
  /**
   * Secondary Button OnClick Action
   */
  secondaryOnClick: PropTypes.func,
  /**
   * Dismiss Button
   */
  dismissButton: PropTypes.bool,
  /**
   * Button Background Color
   */
  buttonBackgroundColor: PropTypes.string,
  /**
   *  Button Alignment
   */
  buttonsLeftAligned: PropTypes.bool,
  /**
   * Content Center
   */
  centered: PropTypes.bool,
  /**
   * Icon
   */
  icon: PropTypes.node,
};
export default PopUp;
