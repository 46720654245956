import queryString from "query-string";

/* phone number in #### ###### format  */
export const formatPhoneNumber = (phoneNumberString) => {
  if (phoneNumberString?.length < 10) return phoneNumberString;
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{4})(\d{6})$/);
  if (match) {
    return "" + match[1] + " " + match[2];
  }
  return null;
};

/* 
 for backend url query parsing only
 queryObject --> Contains all params objects
*/
export const queryStringParseFuntion = (queryObject) => {
  return queryString.stringifyUrl(
    {
      url: "",
      query: queryObject,
    },
    { skipNull: true, skipEmptyString: true }
  );
};

// Function to change Base64 to File type
export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

// fetch extension of a file
export const fileExtension = (file) => {
  const name = file?.name;
  const lastDot = name.lastIndexOf(".");
  return name.substring(lastDot + 1);
};

// convert seconds to hh:mm:ss format
export const formatTime = (seconds) => {
  return new Date(seconds * 1000).toISOString().substr(14, 5);
};

// api base route constant
export const BASE_API = {
  USER: "https://rxccbku42d.execute-api.ap-south-1.amazonaws.com",
  JOB: "https://ls8pbbxod9.execute-api.ap-south-1.amazonaws.com",
  GCS: "https://2y4at9z2v2.execute-api.ap-south-1.amazonaws.com",
  CAPTCHA: "https://nffklilq1b.execute-api.ap-south-1.amazonaws.com",
  DELETE_ACCOUNT: "https://rxccbku42d.execute-api.ap-south-1.amazonaws.com/prod/deleteAccount"
};
