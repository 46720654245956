import React from "react";
import classNames from "classnames";

const Loader = ({ className }) => {
  return (
    <div className={classNames(className)}>
      <svg
        className=" animate-spin p-px"
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
      >
        <g
          transform="translate(1 1)"
          stroke="#fff"
          strokeWidth="1.5"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
        >
          <circle opacity=".3" cx="12" cy="12" r="12" />
          <path
            d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
            strokeDasharray="20"
          />
        </g>
      </svg>
    </div>
  );
};

export default Loader;
