import React from "react";
import toaster from "toasted-notes";
import {
  XIcon,
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";

/**
 * Primary UI component for user interaction
 */

const IconType = ({ type }) => {
  const commonClass =
    "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10 ";
  switch (type) {
    case "warning":
    case "error":
      return (
        <div className={classNames(commonClass, "bg-red-100 text-red-400")}>
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
      );
    case "success":
      return (
        <div className={classNames(commonClass, "bg-green-100 text-green-400")}>
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
      );
    default:
      return (
        <div
          className={classNames(
            commonClass,
            " bg-primary text-primary bg-opacity-25"
          )}
        >
          <InformationCircleIcon
            className="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        </div>
      );
  }
};

const showToast = (type = "info", title, options = {}) => {
  const { subtitle = null, position = "top-right", duration = 2000 } = options;

  toaster.notify(
    ({ onClose }) => (
      <div className="m-4 overflow-hidden border border-gray-200 bg-white shadow-md sm:rounded-lg">
        <div className="relative">
          <div className="flex flex-row space-x-3 p-4 text-left">
            <IconType type={type} />
            <div
              className={classNames(
                "flex w-auto flex-col pr-4",
                subtitle ? "justify-start" : "justify-center"
              )}
            >
              <span className="font-medium text-gray-700">{title}</span>
              {subtitle && (
                <span className="text-xs font-light text-gray-500">
                  {subtitle}
                </span>
              )}
            </div>
            <div>
              <button
                onClick={onClose}
                className="flex-shrink-0 transform text-gray-400 outline-none focus:outline-none"
              >
                <XIcon className="h-5 w-5 text-gray-800" />
              </button>
            </div>
          </div>
        </div>
      </div>
    ),
    { position, duration }
  );
};

export const toast = {
  error: (title, options) => showToast("error", title, options),
  info: (title, options) => showToast("info", title, options),
  success: (title, options) => showToast("success", title, options),
};
