import React from "react";

const Warning = () => {
  return (
    <div className="min-w-screen bg-light-gray font-grotesk relative flex min-h-screen flex-col items-center lg:flex-row lg:justify-between">
      <div className="absolute top-0 right-0">
        <img
          src="/assets/images/placeholders/path.svg"
          alt="background line 1"
        />
      </div>
      <div className="absolute bottom-0 left-0">
        <img
          src="/assets/images/placeholders/path2.svg"
          alt="background line 2"
        />
      </div>

      <div className="flex flex-col items-center justify-between lg:items-start lg:pl-20">
        <div>
          <p className="lg:text-large-5xl mt-20 text-4xl font-bold  uppercase lg:mt-0">
            urdu reader
          </p>
        </div>
        <div className="mt-20 text-center lg:text-left xl:mt-24">
          <p className="lg:text-large-4xl text-3xl font-bold">
            Incompatible&nbsp;device
          </p>
          <p className="mt-4 w-full px-2 opacity-80 lg:w-4/5 lg:px-0 lg:text-xl">
            Sorry, this application is only viewable for mobile devices below
            480px size.
          </p>
        </div>
      </div>
      <div className="relative">
        <div className="mx-16 my-16 w-56 lg:w-80 xl:mx-20 xl:my-0">
          <img
            className="object-cover"
            src="/assets/images/placeholders/phone.png"
            alt="phone"
          />
        </div>

        <div className="absolute bottom-44 xl:left-1 xl:bottom-28">
          <img
            src="/assets/images/placeholders/processingBar.svg"
            alt="processingbar"
            className="w-56 lg:w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default Warning;
