import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "../../stories/toast/Toast";
import { Formik, Form } from "formik";
import Button from "../../stories/buttons/Button";
import { loginValidation } from "../../forms/validation";
import FormFields from "../../utils/FormFields";
import _ from "lodash";
import { API } from "../../lib/network/API";
import { navigate } from "@reach/router";
import { BASE_API } from "../../utils/helper";

const Login = ({ location }) => {
  const captchaRef = useRef(null);
  const initialValues = {
    phone: location?.state?.phone
      ? (location?.state?.countryCode ?? "44").concat(location?.state?.phone)
      : "",
    countryCode: location?.state?.countryCode ?? "",
  };
  const buttonRef = useRef(null);
  const formValues = [
    {
      attributeId: "phone",
      placeholder: "Mobile Number",
      type: "PhoneInput",
      countryCode: "countryCode",
    },
  ];
  const onSubmit = async (values, { setSubmitting }) => {
    const token = captchaRef?.current?.getValue();
    if (!!token) {
      const fetchedValues = {
        ..._.pick(values, ["countryCode"]),
        phone: values.phone.slice(values.countryCode.length),
        captchaToken: token,
      };

      const endpoint = `${BASE_API?.USER}/prod/signup/`;
      const { error } = await API.post(endpoint, fetchedValues);
      if (error) {
        toast.error(
          `${error.message ?? "Something went Wrong. Please try again"}`
        );
        buttonRef.current.shake();
      } else {
        toast.success(`Please check your phone for OTP.`);
        navigate("/auth/login-otp", {
          state: fetchedValues,
        });
      }
    } else {
      toast.error(`${"You must confirm you are not a robot"}`);
    }
    captchaRef.current.reset();
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginValidation}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <div className="flex min-h-screen flex-col items-center justify-between bg-light-gray">
            <div className="flex flex-col  items-center pt-2 ">
              <h1 className="pt-6 pb-3 font-grotesk text-xl font-bold tracking-normal">
                Get Started
              </h1>
              <p className="px-2 text-center font-grotesk text-xl font-medium leading-7  tracking-wider text-neutral-700 opacity-80">
                Enter your mobile number
                <br /> to access your account
              </p>
            </div>

            <div className="mt-2 flex flex-col items-center justify-center ">
              <div className="my-4 px-8">
                <FormFields
                  setFieldValue={setFieldValue}
                  values={values}
                  formValues={formValues}
                />
              </div>
            </div>

            <div className="flex flex-col items-center pb-16">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                ref={captchaRef}
              />
              <>
                <p className="my-4 w-10/12 px-2 font-grotesk text-xs font-medium tracking-tight text-neutral-700 xs:tracking-normal">
                  By clicking Continue, you agree to our{" "}
                  <a
                    href="https://www.urdureaderapp.com/terms"
                    className="font-bold"
                  >
                    Terms & Conditions
                  </a>{" "}
                  and that you've read our{" "}
                  <a
                    href="https://www.urdureaderapp.com/privacy-policy"
                    className="font-bold"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
                <Button
                  ref={buttonRef}
                  type="submit"
                  size="lg"
                  className="h-16  w-10/12 px-2 uppercase"
                  loading={isSubmitting}
                >
                  Continue
                </Button>{" "}
              </>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
