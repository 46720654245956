import React from "react";
const ProcessingLoader = () => {
  return (
    <div className="relative">
      <div className="absolute top-0 left-0 ">
        <svg
          className=" animate-spin-slow h-[3.125rem] w-[3.125rem] p-px"
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
        >
          <g
            transform="translate(1 1)"
            stroke="#fff"
            strokeWidth="1.5"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
          >
            <circle opacity=".3" cx="12" cy="12" r="12" />
            <path
              d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
              strokeDasharray="20"
            />
          </g>
        </svg>
      </div>
      <img src="/assets/images/icons/processing.svg" alt="processing" />
    </div>
  );
};
export default ProcessingLoader;