import PopUp from "./PopUp";
export const PopUpWrapper = ({ title, config, fullTitle, desc }) => {
  const { primaryCta, secondaryCta, open, icon } = config;
  const {
    value: primaryCtaValue,
    loading: primaryCtaLoading,
    onClick: primaryCtaOnClick,
  } = primaryCta;
  const { value: secondaryCtaValue, onClick: secondaryCtaOnClick } =
    secondaryCta;
  const { value: openModalValue } = open;
  const { value: iconValue, type: iconType } = icon;
  return (
    <PopUp
      title={fullTitle ? fullTitle : `Delete ${title}`}
      description={
        desc ? desc : `Are you sure you want to delete this ${title}?`
      }
      type={iconType}
      primaryCta={primaryCtaValue}
      secondaryCta={secondaryCtaValue}
      primaryCtaLoading={primaryCtaLoading}
      primaryOnClick={() => {
        primaryCtaOnClick();
      }}
      open={!!openModalValue}
      dismissButtonOnClick={() => {
        secondaryCtaOnClick(false);
      }}
      secondaryOnClick={() => {
        secondaryCtaOnClick(false);
      }}
      icon={iconValue}
    />
  );
};
