import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, useField } from "formik";
import classNames from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

const TextInput = ({ showErrorIcon = true, disable = false, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div>
      <div className="relative mt-1 pb-2 ">
        <input
          className={classNames(
            "xxxs:h-18 xxxs:w-76 block h-16 w-64 rounded-xl border  border-gray-300 p-4 text-base  font-medium text-neutral-800 shadow focus:border-gray-900 focus:ring-gray-900",
            meta.touched &&
              meta.error &&
              " border-red-600 focus:border-red-600 focus:ring-red-600"
          )}
          name={props.name}
          disabled={!!disable}
          autoComplete="none"
          {...field}
          {...props}
        />
        {showErrorIcon && meta.touched && meta.error && (
          <ExclamationCircleIcon
            className=" absolute top-5 right-3 h-9 w-9 text-red-500"
            aria-hidden="true"
          />
        )}
      </div>
      <ErrorMessage
        name={props.name}
        component="span"
        className="text-custom-red text-md px-2 font-normal capitalize"
      ></ErrorMessage>
    </div>
  );
};

TextInput.defaultProps = {};

TextInput.propTypes = {
  /**
   * TextInput Type
   */
  type: PropTypes.string.isRequired,

  /**
   * TextInput name attribute
   */
  name: PropTypes.string,
};
export default TextInput;
