import React from "react";
const Spacer = ({ height, width }) => {
  return (
    <div
      style={{
        height: height ? `${height / 10}rem` : "100%",
        width: width ? `${width / 10}rem` : "100%",
      }}
    />
  );
};
export default Spacer;
