import React, { useState, useRef } from "react";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { imgPreview } from "./editor-helper/img-preview";
import SettingsMenu from "./editor-helper/SettingsMenu";
import { API } from "../../lib/network/API";

import { dataURLtoFile } from "../../utils/helper";
import Modal from "../Modal";
import { upload } from "@testing-library/user-event/dist/upload";
import { toast } from "../toast/Toast";
import axios from "axios";
import { CurrentUser } from "../../lib/network/util/auth";
import { BASE_API } from "../../utils/helper";

// This is to make and center a % aspect crop
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ImageEditor = ({
  openEditorModal,
  setOpenEditorModal,
  fileInputFuntiion,
  imgSrc,
  setImgSrc,
  crop,
  setCrop,
  finalImg,
  setFinalImg,
  finalFile,
  setFinalFile,
  jobUUID,
}) => {
  const imgRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1);
  const [grayscale, setgrayscale] = useState(false);
  const [progressPercent, setProgressPercent] = useState("");
  const [isDisabled, setDisabled] = useState(false);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(1);
      setCrop(centerAspectCrop(width, height, 1));
    }
  }
  function handleToggleGrayscaleClick() {
    if (grayscale) {
      setgrayscale(false);
    } else {
      setgrayscale(true);
    }
  }
  async function downloadHandler() {
    setDisabled(true);
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      const imgData = await imgPreview(
        imgRef.current,
        completedCrop,
        scale,
        rotate,
        grayscale
      );
      // setFinalImg(imgData);
      const fileExt = imgData.split(";")[0].split("/")[1];
      const fileName = `IMG_${
        Date.now() + (fileExt ? "." + fileExt : ".jpeg")
      }`;
      const file = dataURLtoFile(imgData, fileName);
      uploadFileHandler(file);
      //reset values
      setFinalFile(file);
      setgrayscale(false);
      setAspect(1);
      setScale(1);
      setRotate(0);
      //close modal after file is edited
      // setOpenEditorModal(false);
    }
  }

  const uploadFileHandler = async (file) => {
    const { data: urlData, error: urlError } = await API.post(
      `${BASE_API?.GCS}/prod/getSignedUrlGCS`,
      {
        key: `${CurrentUser?.getId()}/${jobUUID}/${
          file.name ?? "jobImage.jpg"
        }`,
      }
    );

    if (urlError) {
      toast.error(urlError.message ?? "Image upload error");
    } else {
      const url = urlData?.url;

      uploadToGcs(url, file);
    }
  };

  const uploadToGcs = (url, file) => {
    var instance = axios.create({});

    delete instance.defaults.headers.common["Authorization"];
    delete instance.defaults.headers.put["Content-Type"];

    instance
      .put(url, file, {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          setProgressPercent(percentCompleted);
        },
      })
      .then((_) => {
        const uploadedUrl = url?.split("?")[0];
        setFinalImg(uploadedUrl);
        setOpenEditorModal(false);
        setDisabled(false);
      })
      .catch((error) => {
        toast.error("Image upload failed");
        setDisabled(false);
      });
  };

  const closeModalHandler = () => {
    const res = window.confirm(
      "Are you sure you want to leave. Unsaved changes will be lost?"
    );
    if (!!res) {
      setgrayscale(false);
      setAspect(1);
      setScale(1);
      setRotate(0);
      //close modal after file is edited
      setOpenEditorModal(false);
    }
  };

  return (
    <Modal
      openEditorModal={openEditorModal}
      setOpenEditorModal={setOpenEditorModal}
      downloadHandler={downloadHandler}
      closeModalHandler={closeModalHandler}
      progressPercent={progressPercent}
      isDisabled={isDisabled}
    >
      <div>
        <div className="App flex h-[90vh] flex-col justify-evenly overflow-y-auto">
          {Boolean(imgSrc) && (
            <div className="flex flex-col  items-center justify-center bg-neutral-300">
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
                keepSelection={true}
              >
                <img
                  className=" h-[40vh] "
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                    filter: grayscale ? `grayscale(100%)` : "none",
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
          )}
          <div className="mx-0.5 w-screen">
            <SettingsMenu
              scale={scale}
              imgSrc={imgSrc}
              rotate={rotate}
              setScale={setScale}
              setRotate={setRotate}
              handleToggleAspectClick={handleToggleAspectClick}
              handleToggleGrayscaleClick={handleToggleGrayscaleClick}
              grayscale={grayscale}
              aspect={aspect}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImageEditor;
