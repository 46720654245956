import React from "react";
import classNames from "classnames";
const Nav = ({
  ctaLeft,
  ctaRight,
  ctaLeftOnClick,
  ctaRightOnClick,
  heading,
  headingOnClick,
  className,
}) => {
  return (
    <div
      className={classNames(
        "relative my-2 flex items-center justify-center py-4",
        className
      )}
    >
      <div
        className=" absolute  left-0 mx-4  active:bg-neutral-300 "
        onClick={ctaLeftOnClick}
      >
        {ctaLeft ? ctaLeft : null}
      </div>

      <div
        onClick={headingOnClick}
        className={classNames("absolute  my-auto text-xl font-bold", className)}
      >
        {heading ? heading : null}
      </div>

      <div
        className="absolute right-0 mx-4 my-auto active:bg-neutral-300"
        onClick={ctaRightOnClick}
      >
        {ctaRight ? ctaRight : null}
      </div>
    </div>
  );
};

export default Nav;
