import ReCAPTCHA from "react-google-recaptcha";
import { navigate, Link } from "@reach/router";
import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import Countdown, { zeroPad } from "react-countdown";
import { toast } from "../../stories/toast/Toast";
import { Formik, Form } from "formik";
import Button from "../../stories/buttons/Button";
import { otpValidation } from "../../forms/validation";
import { formatPhoneNumber } from "../../utils/helper";
import FormFields from "../../utils/FormFields";
import Spacer from "../../stories/Spacer";
import { API } from "../../lib/network/API";
import { CurrentUser } from "../../lib/network/util/auth";
import classNames from "classnames";
import { BASE_API } from "../../utils/helper";

const OtpPage = ({ location }) => {
  const phone = location?.state?.phone;
  const countryCode = location?.state?.countryCode;
  const [disabled, setdisabled] = useState(true);
  const [time, setTime] = useState({ date: Date.now() + 60000 });
  const [errmsg, setErrmsg] = useState(false);
  const [clearOtp, setClearOtp] = useState(false);
  const initialValues = { otp: "" };
  const buttonRef = useRef(null);
  const captchaRef = useRef(null);

  const formValues = [
    {
      attributeId: "otp",
      type: "Otp",
      clearOtpHandler: !!clearOtp,
      setClearOtp: setClearOtp,
      setErrmsg: setErrmsg,
      errmsg: errmsg,
      disable: false,
    },
  ];

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
    if (!phone || !countryCode) {
      navigate("/auth/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyToken = async (token) => {
    const fetchedValues = {
      token: token,
    };
    const endpoint = `${BASE_API?.CAPTCHA}/dev/captchaVerify`;
    const { error } = await API.post(endpoint, fetchedValues);
    if (error) {
      return { success: false };
    } else {
      return { success: true };
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const token = captchaRef?.current?.getValue();
    if (!!token) {
      const valid_token = await verifyToken(token);
      if (!!valid_token?.success) {
        const fetchedValues = {
          phone: phone,
          countryCode: countryCode,
          otp: values?.otp,
        };

        const endpoint = `${BASE_API?.USER}/prod/verify`;

        const { data, error } = await API.post(endpoint, fetchedValues);

        if (error) {
          toast.error(
            `${error.message ?? "Something went Wrong. Please try again"}`
          );
          setErrmsg(true);
          setClearOtp(true); //otp cleared when error occurs
          buttonRef.current.shake();
        } else {
          toast.success(`OTP verified successfully.Redirecting...`);
          await CurrentUser.setToken(data?.token);
          // eslint-disable-next-line
          const [headers, payload, signature] = data?.token?.split(".");
          await CurrentUser.setDetails(atob(payload));

          setTimeout(() => {
            window.location.href = "/";
          }, 1500);
        }
      } else {
        toast.error(`${"Captcha verification error. Please try again"}`);
      }
    } else {
      toast.error(`${"You must confirm you are not a robot"}`);
    }
    captchaRef.current.reset();
  };

  const resendOtpHandler = async () => {
    const token = captchaRef?.current?.getValue();
    if (!!token) {
      const valid_token = await verifyToken(token);
      if (!!valid_token?.success) {
        if (disabled) {
          toast.info("Wait till timer ends");
        } else {
          const fetchedValues = {
            phone: phone,
            countryCode: countryCode,
          };
          setClearOtp(true);
          toast.info(`Requesting new OTP...`);
          const endpoint = `${BASE_API?.USER}/prod/signup/`;
          const { error } = await API.post(endpoint, fetchedValues);
          if (error) {
            toast.error(
              `${error.message ?? "Something went Wrong. Please try again"}`
            );
          } else {
            toast.success(`New OTP sent to mobile...`);
            setdisabled(true);
            setTime({ date: Date.now() + 60000 });
          }
        }
      } else {
        toast.error(`${"Captcha verification error. Please try again"}`);
      }
    } else {
      toast.error(`${"You must confirm you are not a robot"}`);
    }
    captchaRef.current.reset();
  };
  const handleOncomplete = () => {
    setdisabled(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={otpValidation}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <div className="flex min-h-screen flex-col items-center justify-between bg-light-gray">
            <div className="">
              <div className="flex flex-col items-center pt-2 ">
                <h1 className=" pt-6 pb-3 font-grotesk text-xl font-bold tracking-normal">
                  Verification
                </h1>
                <p className="px-2 text-center  align-middle font-grotesk  text-xl font-medium  leading-7 tracking-normal text-neutral-700 opacity-80 xs:tracking-wide">
                  We’ve sent you a 4 digit
                  <br /> verification code to your mobile
                  <br />
                  number, +{countryCode} {formatPhoneNumber(phone)}
                </p>
                <Spacer height={16} />
                <Link
                  to="/auth/login"
                  state={{ phone, countryCode }}
                  className="font-grotesk text-base font-bold uppercase
          tracking-wide hover:underline focus:text-neutral-700 active:underline "
                >
                  {" "}
                  Edit mobile number
                </Link>
              </div>
              <Spacer height={29} />

              <div className="flex  flex-col items-center justify-center ">
                <div className="px-8 ">
                  <FormFields
                    setFieldValue={setFieldValue}
                    values={values}
                    formValues={formValues}
                  />
                </div>
                <Spacer height={24} />
                <Countdown
                  key={Date.now()}
                  date={time.date}
                  intervalDelay={0}
                  precision={0}
                  onComplete={handleOncomplete}
                  autoStart={true}
                  renderer={({ minutes, seconds }) => (
                    <div
                      className={classNames(
                        "font-grotesk text-base font-normal leading-5",
                        !disabled && "hidden"
                      )}
                    >
                      {zeroPad(minutes)}:{zeroPad(seconds)}
                    </div>
                  )}
                />
                <div
                  className={classNames(
                    "px-2 font-grotesk text-base font-normal leading-6 text-neutral-700 xs:tracking-normal",
                    disabled && "hidden"
                  )}
                >
                  I did not recieve a code
                </div>
                <div
                  onClick={resendOtpHandler}
                  className={classNames(
                    "px-2 text-center font-grotesk text-base font-bold uppercase  tracking-wide text-black",
                    !!disabled ? "opacity-20" : "opacity-100"
                  )}
                >
                  resend
                </div>
              </div>
            </div>
            <div className="mt-5 flex w-full flex-col items-center gap-2 pb-16">
              <ReCAPTCHA
                // onChange={onChangeCaptcha}
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                ref={captchaRef}
              />

              <Button
                ref={buttonRef}
                type="submit"
                size="lg"
                className="h-16 w-10/12 px-2 uppercase"
                loading={isSubmitting}
              >
                Continue
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OtpPage;
