import React from "react";
import classNames from "classnames";
import { useState } from "react";
import { Tab } from "@headlessui/react";
import _ from "lodash";

const SettingsMenu = ({
  scale,
  imgSrc,
  rotate,
  setScale,
  setRotate,
  handleToggleAspectClick,
  handleToggleGrayscaleClick,
  grayscale,
  aspect,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(1);
  return (
    <div className="w-full max-w-md px-2 py-16 sm:px-0">
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.Panels className="mt-2">
          <Tab.Panel className={classNames(" rounded-xl pb-2")}>
            <ul>
              <li className="hover:bg-coolGray-100 relative rounded-md p-3">
                <div className="mx-2 flex  flex-col justify-center space-y-2">
                  <div className="flex flex-row items-center space-x-2 text-white">
                    <label
                      className="text-normal tracking-wide"
                      htmlFor="rotate-input"
                    >
                      Rotate:
                    </label>
                    <div className=" absolute left-4 -bottom-1 text-sm font-medium">
                      ({rotate}&#176;)
                    </div>
                    <input
                      className="mt-0.5 appearance-none rounded-2xl border border-gray-400 bg-gray-200 "
                      type="range"
                      min="-360"
                      max="360"
                      step="10"
                      value={rotate}
                      disabled={!imgSrc}
                      onChange={(e) =>
                        setRotate(
                          Math.min(360, Math.max(-360, Number(e.target.value)))
                        )
                      }
                      id="rotate-input"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </Tab.Panel>
          <Tab.Panel className={classNames("rounded-xl  pb-2")}>
            <ul>
              <li className="hover:bg-coolGray-100 relative rounded-md p-3">
                <div className=" mx-2 flex flex-col  justify-between">
                  <div className="flex flex-row items-center  space-x-1">
                    <input
                      id="checkboxAspect"
                      type="checkbox"
                      onChange={handleToggleAspectClick}
                      checked={!!_.isUndefined(aspect)}
                    />
                    <label
                      htmlFor="checkboxAspect"
                      className="font-grotesk text-white"
                    >
                      Custom Aspect ratio
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </Tab.Panel>
          <Tab.Panel className={classNames("rounded-xl  pb-2")}>
            <ul>
              <li className="hover:bg-coolGray-100 relative rounded-md p-3">
                <div className="mx-2 flex flex-row items-center  space-x-1">
                  <input
                    id="checkboxInput"
                    type="checkbox"
                    onChange={handleToggleGrayscaleClick}
                    checked={!!grayscale}
                  />
                  <label
                    htmlFor="checkboxInput"
                    className="font-grotesk text-white"
                  >
                    Gracyscale
                  </label>
                </div>
              </li>
            </ul>
          </Tab.Panel>
        </Tab.Panels>
        <Tab.List className="mt-2 flex space-x-1 rounded-xl bg-black  p-1">
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg  py-2.5 text-sm font-medium leading-none text-gray-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-gray-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-neutral-100 shadow"
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-white",
                "flex flex-col items-center justify-center"
              )
            }
          >
            <img
              src={
                selectedIndex === 0
                  ? "/assets/images/icons/rotateIcon.svg"
                  : "/assets/images/icons/rotateAltIcon.svg"
              }
              alt="rotate"
            />
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg  py-2.5 text-sm font-medium leading-none text-gray-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-gray-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-white shadow"
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-white",
                "flex flex-col items-center justify-center"
              )
            }
          >
            <img
              src={
                selectedIndex === 1
                  ? "/assets/images/icons/aspectIcon.svg"
                  : "/assets/images/icons/aspectAltIcon.svg"
              }
              alt="aspect"
            />
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg  py-2.5 text-sm font-medium leading-none text-gray-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-gray-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-white shadow"
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-white",
                "flex flex-col items-center justify-center"
              )
            }
          >
            <img
              src={
                selectedIndex === 2
                  ? "/assets/images/icons/grayscaleIcon.svg"
                  : "/assets/images/icons/grayscaleAltIcon.svg"
              }
              alt="grayscale"
            />
          </Tab>
        </Tab.List>
      </Tab.Group>
    </div>
  );
};

export default SettingsMenu;
