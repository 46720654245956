import React from "react";
import Otp from "../stories/inputs/OtpInput";
import PhoneInput from "../stories/inputs/PhoneInput";
import TextInput from "../stories/inputs/TextInput";

const FormFields = ({
  formValues,
  values,
  setFieldValue,
  editMode = true,
  formData,
  className,
  innerContainer,
  customInnerRowStyle = null,
  ...rest
}) => {
  return (
    <div className={className ? className : "space-y-1"} {...rest}>
      {formValues?.map((formValue, fieldIdx) => (
        <div className={innerContainer} key={`${fieldIdx}-${formValue.name}`}>
          {(() => {
            switch (formValue.type) {
              case "PhoneInput":
                return (
                  <>
                    {editMode ? (
                      <PhoneInput
                        name={formValue.attributeId}
                        id={formValue.attributeId}
                        countryCode={formValue.countryCode}
                        placeholder={formValue.placeholder}
                      />
                    ) : (
                      ""
                    )}
                  </>
                );
              case "Otp":
                return (
                  <>
                    {editMode ? (
                      <Otp
                        name={formValue.attributeId}
                        id={formValue.attributeId}
                        disable={formValue.disable}
                        clearOtpHandler={formValue?.clearOtpHandler}
                        setClearOtp={formValue?.setClearOtp}
                        setErrmsg={formValue?.setErrmsg}
                        errmsg={formValue?.errmsg}
                      />
                    ) : (
                      ""
                    )}
                  </>
                );
              case "TextInput":
                return (
                  <>
                    {editMode ? (
                      <TextInput
                        name={formValue.attributeId}
                        id={formValue.attributeId}
                        type={formValue.inputType || "text"}
                        placeholder={formValue.placeholder}
                        disable={formValue?.disable}
                      />
                    ) : (
                      ""
                    )}
                  </>
                );
              default:
                return null;
            }
          })()}
        </div>
      ))}
    </div>
  );
};

export default FormFields;
