import { canvasPreview } from "./canvas-preview";

let previewUrl = "";

function toBlob(canvas) {
  return new Promise((resolve) => {
    canvas.toBlob(resolve);
  });
}

//renders image draw funciton
export async function imgPreview(
  image,
  crop,
  scale = 1,
  rotate = 0,
  grayscale
) {
  const canvas = document.createElement("canvas");
  canvasPreview(image, canvas, crop, scale, rotate, grayscale);
  if (previewUrl) {
    URL.revokeObjectURL(previewUrl);
  }

  // const blob = await toBlob(canvas);
  // previewUrl = URL.createObjectURL(blob);
  // return previewUrl;

  var pngUrl = canvas.toDataURL("image/jpeg", 0.7);

  return pngUrl;
}
