import React from "react";

const UploadImageItem = ({
  todo,
  onRemove,
  removeIcon,
  dragIcon,
  onEdit,
  index,
  displayFileName,
}) => {
  return (
    <div key={index}>
      <div className="group xxs:h-52 xxs:w-40 relative flex aspect-[10/7] h-44 w-32 items-center justify-center overflow-hidden rounded-lg bg-white drop-shadow-xl ">
        <div className="absolute top-2 right-2">
          {dragIcon ? (
            <img src="/assets/images/icons/drag.svg" alt="drag" />
          ) : null}
        </div>
        <div className="absolute top-2 left-2" onClick={() => onRemove(index)}>
          {removeIcon ? (
            <img src="/assets/images/icons/subtract.svg" alt="remove" />
          ) : null}
        </div>
        <div>
          <img className="bg-white object-contain" src={todo?.src} alt="img" />
        </div>
      </div>
      <p className="font-grotesk text-dark-teal xxs:w-40 my-2 w-32 truncate text-center text-xs font-medium uppercase">
        {displayFileName ? `image : ${index + 1}` : null}
      </p>
    </div>
  );
};

export default UploadImageItem;
