import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, CheckIcon } from "@heroicons/react/outline";

const Modal = ({
  children,
  setOpenEditorModal: setOpen,
  openEditorModal: open,
  downloadHandler,
  closeModalHandler,
  progressPercent,
  isDisabled,
}) => {
  return (
    <Transition.Root show={!!open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex min-h-screen items-center justify-center  px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="fixed  inset-0  bg-black  px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all ">
              <div className="absolute top-0 left-0  block pt-4  ">
                <button
                  type="button"
                  className="mx-3 rounded-md  text-white hover:text-gray-500 focus:outline-none"
                  onClick={() => closeModalHandler()}
                  disabled={isDisabled}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="absolute top-0 right-0  block pt-4  ">
                <button
                  type="button"
                  className="mx-3 rounded-md  text-white hover:text-gray-500 focus:outline-none"
                  onClick={() => downloadHandler()}
                  disabled={isDisabled}
                >
                  {progressPercent ? (
                    `Uploading (${progressPercent}%)`
                  ) : (
                    <>
                      <span className="sr-only">save</span>
                      <CheckIcon className="h-6 w-6" aria-hidden="true" />
                    </>
                  )}
                </button>
              </div>
              <div>
                <div className=" mx-auto flex items-center justify-center rounded-full py-4">
                  {children}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
