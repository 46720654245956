import * as Yup from "yup";
import _ from "lodash";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const onlyNumbersRegExp = /^[0-9]+$/;
export const loginValidation = Yup.object({
  phone: Yup.string()
    .min(10, "Invalid phone number")
    .required("Please enter phone number")
    .matches(phoneRegExp, "Phone number is not valid"),
});

export const otpValidation = Yup.object({
  otp: Yup.string()
    .min(4, "Incomplete OTP")
    .required("Please enter OTP")
    .matches(onlyNumbersRegExp, "Please enter a valid OTP"),
});

export const FileNameValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .min(2, "Must be 2 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter Job title"),
});
