import React, { useState, useEffect } from "react";
import File from "../stories/File";
import Nav from "../stories/navs/Nav";
import Spacer from "../stories/Spacer";
import Button from "../stories/buttons/Button";
import { Link } from "@reach/router";
import { toast } from "../stories/toast/Toast";
import Drawer from "./Drawer";
import classNames from "classnames";
import { navigate } from "@reach/router";
import _ from "lodash";
import { API } from "../lib/network/API";
import Loader from "../stories/loaders/Loader";
import { FILE_TYPE_MENU } from "../utils/navigation";
import { BASE_API } from "../utils/helper";
import InfoMessage from "../stories/InfoMessage";

import InfiniteScroll from "react-infinite-scroller";

const Home = () => {
  const [emptyScreen, setEmptyscreen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [fileItems, setFileItems] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({});
  const [pageNoParam, setPageNoParam] = useState(1);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorScreen, setErrorScreen] = useState(false);

  const Submit = (e) => {
    e.preventDefault();
    setOpenMenu(!openMenu);
  };

  //menu cta handler
  const ctaMenuOnClickHandler = (menuItem) => {
    navigate("/new-job", {
      state: { fileType: menuItem?.cta ?? "image" },
    });
  };

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);

    const initalFetch = async () => {
      const { data, error } = await API.get(
        `${BASE_API?.JOB}/prod/listJobs?page=1`
      );
      if (error) {
        toast.error(
          `${error.message ?? "Something went Wrong. Please try again"}`
        );
        setErrorScreen(true);
      } else {
        setFileItems(data?.data);
        setPaginationInfo(data?.pagination);
      }

      if (data?.pagination?.totalRecords === 0) {
        setEmptyscreen(true);
      }
    };

    initalFetch();
  }, []);

  // play/plause handler
  const ctaOnClickHandler = (fileItem, setPlaying) => {
    if (fileItem?.status === 2) {
      setPlaying(fileItem?.id);
    }
  };

  const loadFunc = async () => {
    if (pageNoParam < paginationInfo?.totalPages) {
      const endpoint = `${BASE_API?.JOB}/prod/listJobs?page=${pageNoParam + 1}`;
      const { data, error } = await API.get(endpoint);
      if (error) {
        toast.error(
          `${error?.message ?? "Something went Wrong. Please try again"}`
        );
        setErrorMessage(error?.message);
      } else {
        const modifiedfiles = data?.data;
        const modifiedPagination = data?.pagination;
        setFileItems((fileItems) => [...fileItems, ...modifiedfiles]);
        setPaginationInfo(modifiedPagination);
        setPageNoParam(
          modifiedPagination?.totalPages <= pageNoParam
            ? pageNoParam
            : pageNoParam + 1
        );
      }
    }
  };

  return (
    <div className="bg-light-gray font-grotesk min-h-screen pb-4 ">
      <div className="bg-light-gray sticky top-0 z-50 py-2 ">
        <Nav
          ctaLeft="My Files"
          ctaRight={
            <Link to="/menu">
              <img
                className="my-1.5 h-6 w-6"
                src="/assets/images/icons/hamburgerMenu.svg"
                alt="menu"
              />
            </Link>
          }
          className="text-4xl font-bold"
        />
      </div>
      <Spacer height={10} />

      {!emptyScreen ? (
        <>
          <div className="mx-4">
            {_.isEmpty(paginationInfo) ? (
              <div className="font-grotesk flex  items-center justify-center">
                {!!errorScreen ? (
                  <InfoMessage
                    title="Somthing went wrong"
                    description="We’re facing some technical issue, please check back after sometime or refresh the page."
                    ctaTitle="Try Again"
                  />
                ) : (
                  <Loader title={`Fetching files data ...`} />
                )}
              </div>
            ) : (
              <>
                <File ctaOnClick={ctaOnClickHandler} fileItems={fileItems} />
                <InfiniteScroll
                  pageStart={0}
                  loadMore={loadFunc}
                  hasMore={!!(pageNoParam < paginationInfo?.totalPages)}
                  loader={
                    <div
                      className="loader font-grotesk flex  items-center justify-center"
                      key={0}
                    >
                      <Loader title={`Fetching more files data ...`} />
                    </div>
                  }
                >
                  <Spacer height={2} />
                </InfiniteScroll>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center justify-center">
            <img
              className="mt-[1.438rem] px-9"
              src="/assets/images/placeholders/homescreen.svg"
              alt="pic"
            />
          </div>
          <p className="font-grotesk mt-[2.563rem] text-center text-xl font-bold text-black">
            Create a new file
          </p>
          <p className="font-grotesk mt-3 text-center text-xl font-medium text-neutral-700 opacity-80">
            Convert your document into
            <br /> audio by clicking the
            <br /> button below
          </p>
        </>
      )}
      <div>
        {openMenu === false ? (
          <div
            className={classNames(
              !emptyScreen
                ? "fixed bottom-8 right-4 z-50 mx-auto flex w-auto flex-col items-end pb-1"
                : "mt-5 flex items-center justify-center"
            )}
          >
            <Button
              type="submit"
              size="lg"
              className="h-14 w-14 rounded-2xl  shadow-none drop-shadow-none"
              onClick={Submit}
            >
              <div className="text-4xl font-normal">+</div>
            </Button>
          </div>
        ) : null}
      </div>
      <Drawer
        menuItems={FILE_TYPE_MENU}
        menuTitle="Select your source"
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        ctaMenuOnClickHandler={ctaMenuOnClickHandler}
      />
    </div>
  );
};

export default Home;
