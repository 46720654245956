import React, { useEffect, useRef, useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { navigate, useLocation } from "@reach/router";
import Nav from "../stories/navs/Nav";
import Spacer from "../stories/Spacer";
import { Formik, Form } from "formik";
import FormFields from "../utils/FormFields";
import { FileNameValidationSchema } from "../forms/validation";
import Button from "../stories/buttons/Button";
import { API } from "../lib/network/API";
import { toast } from "../stories/toast/Toast";
import { BASE_API } from "../utils/helper";

const NewJob = () => {
  const location = useLocation();
  const fileType = location?.state?.fileType;
  const renameData = location?.state?.name && location?.state?.id;
  const [editData, setEditData] = useState(
    renameData
      ? { name: location?.state?.name, id: location?.state?.id }
      : false
  );
  const buttonRef = useRef(null);
  const initialValues = { name: "" };

  const formValues = [
    {
      attributeId: "name",
      placeholder: "Enter File Name",
      type: "TextInput",
      disable: false,
    },
  ];

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);

    if (!["pdf", "word", "image"].includes(fileType)) {
      if (!renameData) {
        navigate("/", { replace: true });
      }
    }
  }, []);

  const onSubmit = async (values, { setSubmitting }) => {
    const fetchedValues = !!renameData
      ? { name: values?.name.trim() }
      : {
          name: values?.name.trim(),
          isFile: ["pdf", "word"].includes(fileType),
          fileExt: ["pdf", "word"].includes(fileType) ? fileType : null,
          status: "draft",
          attachments: [],
          jobUUID: values?.name
            .trim()
            .replace(/[^a-zA-Z0-9 ]/g, "")
            .replace(/\s\s+/g, " ")
            .replace(/[ ]/g, "_")
            .toString()
            .concat("_")
            .concat(Date.now()),
        };
    const endpoint = !!renameData
      ? `${BASE_API?.JOB}/prod/updateJob/?id=${values?.id}`
      : `${BASE_API?.JOB}/prod/createJob/`;

    const { error, data } = await API.post(endpoint, fetchedValues);
    if (error) {
      toast.error(
        `${error.message ?? "Something went Wrong. Please try again"}`
      );
    } else {
      toast.success(!!renameData ? `File name updated` : `New File Created.`);
      if (!!renameData) {
        navigate(
          `/add-file/${values?.id}`,
          { replace: true },
          {
            state: {
              id: values?.id,
              fileName: values?.name,
              fileType: fileType,
            },
          }
        );
      } else {
        navigate(
          `/add-file/${data?.data?.id}`,
          { replace: true },
          {
            state: { id: data?.id, fileName: values?.name, fileType: fileType },
          }
        );
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={renameData ? editData : initialValues}
        validationSchema={FileNameValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="bg-light-gray font-grotesk relative min-h-screen py-4">
              <Nav
                ctaLeft={<ArrowLeftIcon className="my-0.5 h-6 w-6" />}
                ctaLeftOnClick={() => {
                  navigate(-1);
                }}
                heading={!!renameData ? "Rename File" : "File Name"}
                className=" font-bold capitalize "
              />
              <Spacer height={7.5} />
              <div className="flex flex-col items-center pt-2 ">
                <p className="font-grotesk xs:tracking-wide px-3 text-center  align-middle  text-xl  font-medium leading-7 tracking-normal text-neutral-700 opacity-80">
                  {!!renameData
                    ? "Enter the updated name for the file"
                    : "Enter a file name for the process to audio conversion"}
                </p>
                <Spacer height={60} />
                <div className="my-4 px-8 ">
                  <FormFields
                    setFieldValue={setFieldValue}
                    values={values}
                    formValues={formValues}
                  />
                </div>
              </div>
              <div className="fixed inset-x-0 bottom-2 flex w-full flex-col items-center justify-around ">
                <Button
                  ref={buttonRef}
                  type="submit"
                  size="lg"
                  className=" h-16 w-10/12 px-2 uppercase "
                  loading={isSubmitting}
                >
                  next
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewJob;
