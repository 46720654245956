import React from "react";
import { Router, Redirect } from "@reach/router";
import { CurrentUser } from "../src/lib/network/util/auth";

import Login from "./pages/auth/Login";
import OtpPage from "./pages/auth/OtpPage";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Warning from "./pages/Warning";
import Menu from "./pages/Menu";
import JobDetail from "./pages/JobDetail";
import NewJob from "./pages/NewJob";
import AddFile from "./pages/AddFile";
import Success from "./pages/Success";
import Pricing from "./pages/Pricing";

const ProtectedRoute = ({ children }) => {
  const token = CurrentUser.getToken();
  return !!token ? children : <Redirect to="/auth/login" noThrow />;
};

const PublicRoute = ({ children }) => {
  const token = CurrentUser.getToken();
  return !!token ? <Redirect to="/" noThrow /> : children;
};

const App = () => {
  return (
    <>
      <div className="maxPoint:block hidden">
        <Warning />
      </div>
      <div className="maxPoint:hidden block">
        <Router>
          <PublicRoute path="/auth">
            <Login path="/login" />
            <OtpPage path="/login-otp" />
            <NotFound default />
          </PublicRoute>
          <ProtectedRoute path="/">
            <Home path="/" />
            <Menu path="/menu" />
            <NotFound default />
            <JobDetail path="/job-details/:id" />
            <NewJob path="/new-job" />
            <AddFile path="/add-file/:id" />
            <Success path="/success" />
            <Pricing path="/pricing" />
          </ProtectedRoute>
          <Warning path="/incompatible-device" />
          <NotFound default />
        </Router>
      </div>
    </>
  );
};

export default App;
