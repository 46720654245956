import React, { useState } from "react";
import { Link } from "@reach/router";
import Nav from "../stories/navs/Nav";
import { logout } from "../lib/network/util/auth";
import FullScreeModal from "../stories/modal/FullScreenModal";
import { API } from "../lib/network/API";
import { BASE_API } from "../utils/helper";
import { toast } from "../stories/toast/Toast";
import Loader from "../stories/loaders/Loader";

const Menu = () => {

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [accountDeleting, setAccountDeleting] = useState(false);

  const data = [
    {
      id: 0,
      title: "Pricing",
      logo: "/assets/images/icons/currency.svg",
      link: "/pricing",
    },
    {
      id: 1,
      title: "Terms & Conditions",
      logo: "/assets/images/icons/file.svg",
      link: "https://www.urdureaderapp.com/terms",
    },
    {
      id: 2,
      title: "Privacy Policy",
      logo: "/assets/images/icons/shield.svg",
      link: "https://www.urdureaderapp.com/privacy-policy",
    },
    {
      id: 3,
      title: "Disclaimer",
      logo: "/assets/images/icons/i.svg",
      link: "https://www.urdureaderapp.com/disclaimer",
    },
    {
      id: 4,
      title: "Delete Account",
      logo: "/assets/images/icons/trash.svg",
      action: () => setOpenDeleteModal(true)
    },
  ];

  const menuList = (list) => {
    return (
      <div className="flex flex-row items-center justify-between ">
        <div className="flex flex-row items-center justify-start">
          <div className="flex h-[2.625rem] w-[2.625rem] items-center justify-center rounded-full bg-black">
            <img src={list?.logo} alt="icon" />
          </div>
          <div>
            <p className="font-grotesk ml-4 text-lg font-bold text-black">
              {list?.title}
            </p>
          </div>
        </div>
        <div>
          <img src="/assets/images/icons/arrow.png" alt="arrow" />
        </div>
      </div>
    );
  };

  const onDeletePress = async () => {
    setOpenDeleteModal(false);
    setAccountDeleting(true);
    const currentUserDetails = localStorage.getItem("current_user_details");
    const parsedUserDetails = JSON.parse(currentUserDetails);
    console.log(parsedUserDetails)
    try {
      const { error } = await API.post(`${BASE_API.DELETE_ACCOUNT}?id=${parsedUserDetails?.user?.id}`, {})
      if (error) {
        setAccountDeleting(false);
        toast.error(
          `${error.message ?? "Something went Wrong. Please try again"}`
        );
      } else {
        setAccountDeleting(false);
        toast.success(`Your account has been deleted.`);
        localStorage.removeItem("current_user_details");
        localStorage.removeItem("user_auth_token");
        window.location.href = "/auth/login";
      }
    } catch (error) {
      setAccountDeleting(true);
      toast.error(
        `${error.message ?? "Something went Wrong. Please try again"}`
      );
    }
  }

  return (
    <div className="bg-light-gray font-grotesk min-h-screen py-4">
      <Nav
        ctaLeft={
          <div className="font-grotesk text-2xl font-bold text-black">Menu</div>
        }
        ctaRight={
          <Link to="/" className="flex items-center justify-center">
            <img src="/assets/images/icons/close.svg" alt="close" />
          </Link>
        }
      />
      <div className="mx-4 mt-[1.625rem] rounded-[0.938rem] bg-white py-3.5 px-[1.625rem] shadow-[0_12px_19px_0px_rgba(0,0,0,0.09)]">
        {data.map((list) => (
          <div className="py-3" key={list?.id}>
            {list.action ? <div onClick={list.action}>{menuList(list)}</div> : list?.id === 0 ? (
              <Link to={list?.link}>{menuList(list)}</Link>
            ) : (
              <a href={list?.link}>{menuList(list)}</a>
            )}
          </div>
        ))}
      </div>
      <div className="mt-10 flex items-center justify-center">
        <p
          onClick={logout}
          className="font-grotesk w-[4.688rem] text-base font-bold uppercase tracking-[.06em] text-red-500"
        >
          log out
        </p>
      </div>
      <FullScreeModal openEditorModal={openDeleteModal} setOpenEditorModal={() => setOpenDeleteModal(false)}>
        <div className="flex items-center justify-center w-full h-full p-4">
          <div className="bg-white rounded-lg p-3">
            <h1 className="text-center text-lg font-grotesk font-bold text-black">
              Are you sure you want to delete your account?
            </h1>
            <p className="mt-3 text-[#737373] font-grotesk font-medium leading-6">1. Deleting your account will result in the permanent loss of all your saved information, Files, and any associated content.</p>
            <p className="mt-3 text-[#737373] font-grotesk font-medium leading-6">2. In the event that an attempt is made to create a new account using a mobile number that was previously deleted, please note that the newly created account will be treated as an entirely separate entity and will not be linked to any previous data or information.</p>
            <div className="flex items-center justify-between gap-4 mt-6">
              <button onClick={onDeletePress} className="flex-1 h-10 border border-[#262626] font-grotesk rounded-lg font-bold">DELETE</button>
              <button onClick={() => setOpenDeleteModal(false)} className="flex-1 h-10 rounded-lg bg-[#EF4444] font-grotesk text-white font-bold">CANCEL</button>
            </div>
          </div>
        </div>
      </FullScreeModal>
      {accountDeleting ? <div className="absolute w-full h-screen inset-0 flex items-center justify-center bg-black/60">
        <Loader className={"text-white h-8 w-8 "} />
      </div> : null}
    </div>
  );
};

export default Menu;
