import React from "react";
import { navigate } from "@reach/router";
import classNames from "classnames";
import _ from "lodash";

const InfoMessage = ({ title, description, ctaTitle, ctaLink, size }) => {
  const ctaHandler = () => {
    if (_.isUndefined(ctaLink)) {
      window.location.reload();
    } else {
      navigate(ctaLink);
    }
  };

  return (
    <div
      className={classNames(
        "font-grotesk flex flex-col items-center justify-evenly",
        size === "full" ? "min-h-screen" : ""
      )}
    >
      <div className="mb-2">
        <img
          src="/assets/images/placeholders/successScreen.svg"
          alt="success message"
        />
      </div>
      <div className="my-2">
        <p className="text-center text-xl font-bold capitalize text-black">
          {title}
        </p>
        <p className="xxxs:w-72 mt-3 text-center text-xl font-medium text-neutral-700 opacity-80">
          {description}
        </p>
      </div>
      <div className="mt-2 mb-8">
        <p
          className="text-base font-bold uppercase tracking-wider"
          onClick={ctaHandler}
        >
          {ctaTitle}
        </p>
      </div>
    </div>
  );
};

InfoMessage.defaultProps = {
  title: "submitted successfully",
  description: "It may take a few minutes to convert into audio.",
  ctaTitle: "back to home",
};

export default InfoMessage;
