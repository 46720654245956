import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Loader from "../stories/loaders/Loader";

const Upload = ({
  ctaAddOnClick,
  fileName,
  displayFile,
  removeFile,
  filetype,
  finalPdfLink,
  showIcon,
  pdfPages,
  setPdfPages,
}) => {
  return (
    <>
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {!displayFile ? (
          <li className="relative drop-shadow-xl">
            <div
              onClick={ctaAddOnClick}
              className="group xxs:h-52 xxs:w-40 relative flex aspect-[10/7] h-44 w-32 items-center justify-center overflow-hidden rounded-lg bg-white "
            >
              <img
                src="/assets/images/icons/addIcon.svg"
                alt=""
                className=" pointer-events-none h-14 w-14 object-cover "
              />
            </div>
            <p className="font-grotesk text-dark-teal xxs:w-40 my-2 w-32 text-center text-xs font-medium">
              {filetype === "word" ? "Add Document" : "Add Pdf File"}
            </p>
          </li>
        ) : (
          <li className="relative drop-shadow-xl">
            <div className="group xxs:h-52 xxs:w-40 relative flex aspect-[10/7] h-44 w-32 items-center justify-center overflow-hidden rounded-lg bg-white ">
              <div onClick={removeFile} className="absolute top-1 left-1 z-10">
                <img
                  src="/assets/images/icons/subtract.svg"
                  alt=""
                  className=" pointer-events-none object-cover"
                />
              </div>
              {filetype === "word" ? (
                !finalPdfLink ? (
                  <div className="mt-4 ml-4">
                    <Loader />
                  </div>
                ) : (
                  <img src="/assets/images/icons/wordIcon.svg" alt="doc-file" />
                )
              ) : (
                <Document
                  file={finalPdfLink}
                  noData={
                    <div className="mt-4 ml-4">
                      <Loader />
                    </div>
                  }
                  loading={
                    <div className="mt-4 ml-4">
                      <Loader />
                    </div>
                  }
                  onLoadSuccess={(pdf) => setPdfPages(pdf.numPages)}
                >
                  <Page pageNumber={1} height={175} />
                </Document>
              )}
              {/* )} */}
            </div>
            <p className="font-grotesk text-dark-teal xxs:w-40 my-2 w-32 truncate px-5 text-center text-xs font-medium">
              {filetype === "word"
                ? "Document"
                : `Pdf file ${
                    pdfPages
                      ? " (" +
                        pdfPages +
                        `${pdfPages > 1 ? " pages" : " page"}` +
                        ")"
                      : ""
                  }`}
            </p>
          </li>
        )}
      </ul>
    </>
  );
};

export default Upload;
