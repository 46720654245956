import React from "react";
import InfoMessage from "../stories/InfoMessage";

const Success = () => {
  return (
    <div className="bg-light-gray pt-2 pb-4">
      <InfoMessage size="full" ctaLink="/" />
    </div>
  );
};

export default Success;
