import React, { useEffect, useState } from "react";
import { ArrowLeftIcon, RefreshIcon } from "@heroicons/react/solid";
import { ExclamationIcon } from "@heroicons/react/outline";
import { navigate } from "@reach/router";
import Nav from "../stories/navs/Nav";
import moment from "moment";
import SoundPlayer from "../stories/SoundPlayer";
import { useLocation } from "@reach/router";
import classNames from "classnames";
import Loader from "../stories/loaders/Loader";
import ProcessingLoader from "../stories/loaders/ProcessingLoader";
import { API } from "../lib/network/API";
import { toast } from "../stories/toast/Toast";
import { BASE_API } from "../utils/helper";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { FILE_DELETE_MENU } from "../utils/navigation";
import Drawer from "./Drawer";
import { PopUpWrapper } from "../stories/pop-up/PopUpWrapper";
import InfoMessage from "../stories/InfoMessage";

const JobDetails = ({ id }) => {
  const location = useLocation();
  const jobId = id;
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [editMenu, setEditMenu] = useState(false);
  const [errorScreen, setErrorScreen] = useState(false);
  const [pdfPages, setPdfPages] = useState("");

  // delete popup and loading states
  const [deleteId, setDeleteId] = useState(false);
  const [deletePopUpPrimaryCtaLoading, setDeletePopUpPrimaryCtaLoading] =
    useState(false);

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
    const initalFetch = async () => {
      const { data, error } = await API.get(
        `${BASE_API?.JOB}/prod/showJob?id=${jobId}`
      );
      if (error) {
        toast.error(
          `${error.message ?? "Something went Wrong. Please try again"}`
        );
        setErrorScreen(true);
      } else {
        if ([0].includes(data?.data?.status)) {
          navigate("/", { replace: true });
        } else {
          setData(data?.data);
          setLoader(false);
        }
      }
    };

    if (jobId) {
      initalFetch();
    }
  }, []);

  const statusInfo = () => {
    switch (data?.status) {
      case 0:
        return {
          status: "draft",
          statusIcon: "/assets/images/icons/draft.svg",
          textMsg: "Draft",
        };
      case 1:
        return {
          status: "processing",
          statusIcon: "/assets/images/icons/processing.svg",
          textMsg: "We are working on it.",
        };
      case 2:
        return {
          status: "completed",
          statusIcon: "/assets/images/icons/download.svg",
          textMsg: "Download audio file",
        };
      case 4:
        return {
          status: "failed",
          statusIcon: "/assets/images/icons/error.svg",
          textMsg: "Failed",
        };
      default:
        return {
          status: "error",
          statusIcon: "/assets/images/icons/error.svg",
          textMsg: "Error",
        };
    }
  };

  // set loader
  // if no attachment in data
  if (!data?.attachments) {
    setTimeout(() => {
      setShow(true);
    }, 0);
  }

  // if images in data
  const onLoad = (idx) => {
    if (idx === data?.attachments.length - 1) {
      setTimeout(() => {
        setShow(true);
      }, 1000);
    }
  };

  // edit menu open
  const ctaEditMenuHandler = (menuItem) => {
    setDeleteId(jobId);
    setEditMenu(false);
  };

  // delete job
  const discardJobHandler = async () => {
    if (deleteId) {
      setDeletePopUpPrimaryCtaLoading(true);
      const endpoint = `${BASE_API?.JOB}/prod/deleteJob/?id=${jobId}`;

      const { error } = await API.post(endpoint);
      setDeletePopUpPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `${error.message ?? "Something went Wrong. Please try again"}`
        );
      } else {
        toast.success(`File Deleted Sucessfully.`);
        navigate("/", { replace: true });
        setDeleteId(false);
      }
    } else {
      toast.error("Somthing went wrong. Please try again.");
    }
  };

  // discard job
  const deletePopUpConfig = {
    primaryCta: {
      value: "Proceed",
      loading: deletePopUpPrimaryCtaLoading,
      onClick: discardJobHandler,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  return (
    <div className="bg-light-gray font-grotesk min-h-screen pb-4">
      {/* delete popup component  function */}
      <PopUpWrapper
        fullTitle="Discard File"
        desc="Do you want to delete this file?"
        config={deletePopUpConfig}
      />
      <div className="bg-light-gray sticky top-0 z-10 py-2 ">
        <Nav
          ctaLeft={<ArrowLeftIcon className="my-0.5 h-6 w-6" />}
          ctaLeftOnClick={() => {
            navigate(-1);
          }}
          ctaRight={
            <>
              {!!editMenu ? (
                <img src="/assets/images/icons/crossIcon.svg" alt="close" />
              ) : null}
              {!!errorScreen ? null : !!editMenu ? null : (
                <div
                  className="mt-0.5"
                  onClick={() => {
                    if (!errorScreen) {
                      setEditMenu(!editMenu);
                    }
                  }}
                >
                  <img
                    src="/assets/images/icons/threeDotMenu.svg"
                    alt="hamburger"
                  />
                </div>
              )}
            </>
          }
        />
        {/* title, status,info,etc block */}
        {!!loader || !!errorScreen ? null : (
          <div className="mx-5">
            <p className="font-grotesk mt-2 text-2xl font-bold capitalize leading-7 text-black">
              {data?.name}
            </p>
            <div className="mt-2.5 flex items-center justify-between">
              <div className="flex items-center justify-start">
                <p className="font-grotesk text-xs font-semibold capitalize tracking-[0.034em] text-black">
                  status
                </p>
                <div
                  className={classNames(
                    "ml-2.5 rounded",
                    data?.status === 4 ? " bg-red-500" : "bg-black",
                    " py-1 px-1.5"
                  )}
                >
                  <p className="font-grotesk text-xs font-bold uppercase tracking-[0.019em] text-white">
                    {statusInfo()?.status}
                  </p>
                </div>
              </div>
              <div className="font-grotesk text-xs font-bold uppercase tracking-[0.019em] text-neutral-500">
                {moment(data?.createdAt).format("MMMM D, YYYY")}
              </div>
            </div>
            <div className="mt-4 border-t-[1px] border-neutral-200"></div>
            {data?.status === 1 ? (
              <>
                <div className="mt-5 flex flex-row items-center justify-start">
                  <ProcessingLoader />
                  <p className="font-grotesk ml-3 text-sm font-semibold text-black">
                    {statusInfo()?.textMsg}
                  </p>
                </div>
                {data?.status === 1 ? (
                  <p className="font-grotesk mt-3 text-xs font-medium leading-[0.875rem] text-neutral-700">
                    Your file may take few minutes to convert into audio.
                  </p>
                ) : null}
                <div className="mt-5 border-t-[1px] border-neutral-200"></div>
              </>
            ) : data?.status === 2 && !!data?.audioFileUrl ? (
              <div className="my-[20px]">
                <SoundPlayer
                  autoPlay={false}
                  audioSrcFile={data?.audioFileUrl}
                />
                <div className="mt-5 border-t-[1px] border-neutral-200"></div>
              </div>
            ) : null}
          </div>
        )}
      </div>

      <div>
        {loader ? (
          <div
            className={classNames(
              "font-grotesk flex items-center justify-center"
            )}
          >
            {!!errorScreen ? (
              <div className="mt-10">
                <InfoMessage
                  title="Somthing went wrong"
                  description="We’re facing some technical issue, please check back after sometime or refresh the page."
                  ctaTitle="Try Again"
                />
              </div>
            ) : (
              <Loader title={`Fetching file details ...`} />
            )}
          </div>
        ) : (
          <div className="mx-5">
            <div>
              <div className=" mt-0.5 flex flex-row items-center justify-between">
                <p className="capitilize font-grotesk text-xs font-medium tracking-wide text-[#01221D]">
                  {!data?.isFile ? "Image(s)" : "File(s)"}
                </p>
                {[null, 1, 4].includes(data?.status) ? (
                  <button
                    onClick={() => window.location.reload(false)}
                    type="button"
                    className="inline-flex items-center rounded-full border border-transparent bg-black p-1 text-white shadow-sm hover:opacity-80 focus:outline-none focus:ring-1 focus:ring-black focus:ring-offset-2"
                  >
                    <RefreshIcon className="h-3 w-3" aria-hidden="true" />
                  </button>
                ) : null}
              </div>
              <div>
                <div
                  className={classNames(
                    "font-grotesk flex h-40 items-center justify-center",
                    show ? "hidden" : "block"
                  )}
                >
                  <Loader title={`Fetching images ...`} />
                </div>

                <div
                  className={classNames(
                    "mt-4 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8",
                    show ? "block" : "hidden"
                  )}
                >
                  {data?.attachments ? (
                    <>
                      {data?.attachments?.map((post, idx) => {
                        return (
                          <div className="mr-0 mt-0" key={idx}>
                            {data?.isFile ? (
                              <>
                                {/* for file */}
                                {post?.src !== null ? (
                                  <div className="group xxs:h-52 xxs:w-40 relative flex aspect-[10/7] h-44 w-32 items-center justify-center overflow-hidden rounded-lg bg-white drop-shadow-xl ">
                                    {data?.fileExt === "word" ? (
                                      <img
                                        src="/assets/images/icons/wordIcon.svg"
                                        alt="doc-file"
                                      />
                                    ) : (
                                      <Document
                                        file={{ url: post?.src ?? "" }}
                                        loading={
                                          <div className="mt-4 ml-4">
                                            <Loader />
                                          </div>
                                        }
                                        onLoadSuccess={(pdf) =>
                                          setPdfPages(pdf.numPages)
                                        }
                                      >
                                        <Page pageNumber={1} height={175} />
                                      </Document>
                                    )}
                                  </div>
                                ) : (
                                  <div className="font-grotesk -ml-8 flex h-40 w-screen items-center justify-center">
                                    <p>No File</p>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {/* for image  */}
                                {post?.src !== null ? (
                                  <div className="group xxs:h-52 xxs:w-40 relative flex aspect-[10/7] h-44 w-32 items-center justify-center overflow-hidden rounded-lg bg-white drop-shadow-xl ">
                                    <img
                                      className="rounded-lg object-cover"
                                      src={post?.src}
                                      alt={post?.src?.substring(
                                        post?.src?.lastIndexOf("/") + 1
                                      )}
                                      onLoad={() => onLoad(idx)}
                                      onError={() => onLoad(idx)}
                                    />
                                  </div>
                                ) : (
                                  // no img, if url null
                                  <div className="font-grotesk -ml-8 flex h-40 w-screen items-center justify-center text-center">
                                    <p>No Image</p>
                                  </div>
                                )}
                              </>
                            )}

                            {/* file or img name */}
                            <p className="font-grotesk xxs:w-[9.375rem] mt-3.5 w-[7.75rem] truncate text-center text-xs font-medium uppercase tracking-wide text-[#01221D]">
                              {data?.fileExt === "word"
                                ? "document"
                                : data?.fileExt === "pdf"
                                ? `Pdf file ${
                                    pdfPages
                                      ? " (" +
                                        pdfPages +
                                        `${pdfPages > 1 ? " pages" : " page"}` +
                                        ")"
                                      : ""
                                  }`
                                : `image : ${idx + 1}`}
                            </p>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    // if no attachment
                    <div className="font-grotesk flex h-40 w-screen items-center justify-center text-center">
                      <p>{!data?.isFile ? "No Image" : "No File"}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* for edit */}
      <Drawer
        menuItems={FILE_DELETE_MENU}
        menuTitle={data?.name}
        openMenu={!!editMenu}
        setOpenMenu={setEditMenu}
        ctaMenuOnClickHandler={ctaEditMenuHandler}
      />
    </div>
  );
};

export default JobDetails;
