import React from "react";
import "react-phone-input-2/lib/bootstrap.css";
import { ErrorMessage, useField, useFormikContext } from "formik";
import classNames from "classnames";
import ReactPhoneInput from "react-phone-input-2";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

const PhoneInput = ({ countryCode, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(props);
  return (
    <div className="relative mt-1">
      <ReactPhoneInput
        inputProps={{ autoComplete: "none" }}
        inputClass={classNames(
          "p-0.5 border-gray-300 focus:ring-2 focus:ring-gray-900 sm:text-sm  w-full focus:border-gray-900 drop-shadow-[0_12px_19px_rgba(0,0,0,0.09)]",
          meta.touched &&
            meta.error &&
            "border w-full focus:ring-2 border-red-600 focus:ring-red-600 focus:border-red-600"
        )}
        containerClass={classNames(
          "phone-input-custom w-full",
          "w-full block sm:text-sm",
          meta.touched &&
            meta.error &&
            "border w-full rounded-2xl border-light-red"
        )}
        buttonClass={classNames("border-r border-neutral-300 my-2")}
        dropdownStyle={{
          width: "80vw",
          height: "60vh",
          marginTop: "15px",
          marginLeft: "0px",
          zIndex: "50",
          borderRadius: "16px",
          overflowX: "hidden",
        }}
        searchPlaceholder="Search for countries"
        searchNotFound="No country found"
        searchStyle={{
          width: "110%",
          padding: "20px 20px 20px 40px",
          fontSize: "1rem",
          marginLeft: "-13px",
          borderBottomWidth: "1px",
          borderTopWidth: "0px",
          borderLeftWidth: "0px",
          borderRightWidth: "0px",
          fontWeight: "500",
          backgroundImage: "url('/assets/images/icons/search.svg')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "4% 50%",
        }}
        inputStyle={{
          width: "80vw",
          borderStyle: "none",
          fontFamily: "HK Grotesk",
          fontSize: "1rem",
          borderRadius: "16px",
          fontWeight: 500,
          height: "70px",
          color: "262626",
        }}
        name={field.name}
        id={field.id}
        {...field}
        {...props}
        country={"gb"}
        enableSearch={true}
        onChange={(value, data) => {
          setFieldValue("countryCode", data.dialCode);
          setFieldValue(field.name, value);
        }}
        onBlur={() => setFieldTouched(field.name, true)}
        inputmode="numeric"
      />
      {meta.touched && meta.error && (
        <ExclamationCircleIcon
          className="absolute top-4 right-3 h-9 w-9 text-red-500"
          aria-hidden="true"
        />
      )}

      <div className="mt-1 ml-1">
        <ErrorMessage
          name={props.name}
          component="span"
          className="text-tomato text-xs font-normal capitalize"
        ></ErrorMessage>
      </div>
    </div>
  );
};

export default PhoneInput;
