import React, { useState, useEffect } from "react";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { ExclamationIcon, CheckIcon } from "@heroicons/react/outline";
import { navigate, useLocation } from "@reach/router";
import Nav from "../stories/navs/Nav";
import Spacer from "../stories/Spacer";
import Upload from "../stories/Upload";
import Drawer from "./Drawer";
import { UploadImages, UploadImages2 } from "../stories/UploadImages";
import { SortableContainer } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { toast } from "../stories/toast/Toast";
import Button from "../stories/buttons/Button";
import Toggle from "../stories/buttons/Toggle";
import classNames from "classnames";
import ImageEditor from "../stories/media/ImageEditor";
import Loader from "../stories/loaders/Loader";
import { API } from "../lib/network/API";
import { FILE_MENU, IMAGE_MENU, FILE_MODIFY_MENU } from "../utils/navigation";
import { BASE_API, fileExtension } from "../utils/helper";
import axios from "axios";
import { CurrentUser } from "../lib/network/util/auth";
import { PopUpWrapper } from "../stories/pop-up/PopUpWrapper";
import InfoMessage from "../stories/InfoMessage";

const SortableList = SortableContainer(UploadImages);

const AddFile = ({ id }) => {
  const location = useLocation();
  const jobId = id;
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const fileType = location?.state?.fileType;
  const [openMenu, setOpenMenu] = useState(false);
  const [editMenu, setEditMenu] = useState(false);

  // file upload
  const [selectedFiles, setSelectedFiles] = useState();
  const [finalPdfLink, setFinalPdfLink] = useState();
  const [showIcon, setShowIcon] = useState(true);
  const [fileName, setFileName] = useState();
  const [filetype, setFiletype] = useState();
  const [displayFile, setDisplayFile] = useState(false);
  const [progressPersent, setProgressPersent] = useState("");
  //pdf upload/preview
  const [pdfPages, setPdfPages] = useState("");

  // Image upload
  const [state, setState] = useState(false);
  const [drag, setDrag] = useState(false);
  const [arrLoad, setArrLoad] = useState(false);
  const [imageItemArr, setImageItemArr] = useState([]);

  // image editor
  const [openEditorModal, setOpenEditorModal] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();

  //this will give you a base64 image data
  const [finalImg, setFinalImg] = useState("");
  //this will store img file
  const [finalFile, setFinalFile] = useState("");

  /* popup states  */
  // delete popup and loading states
  const [deleteId, setDeleteId] = useState(false);
  const [deletePopUpPrimaryCtaLoading, setDeletePopUpPrimaryCtaLoading] =
    useState(false);

  // draft popup and loading states
  const [draftId, setDraftId] = useState(false);
  const [draftPopUpPrimaryCtaLoading, setDraftPopUpPrimaryCtaLoading] =
    useState(false);

  // inProgress popup and loading states
  const [inProgressId, setInProgressId] = useState(false);
  const [
    inProgressPopUpPrimaryCtaLoading,
    setInProgressPopUpPrimaryCtaLoading,
  ] = useState(false);
  /*  */

  // err screen
  const [errorScreen, setErrorScreen] = useState(false);

  // add value to arr
  useEffect(() => {
    if (arrLoad) {
      setImageItemArr([
        ...imageItemArr,
        {
          src: finalImg,
        },
      ]);
    }
  }, [finalImg]);

  // for api
  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
    const initalFetch = async () => {
      const { data, error } = await API.get(
        `${BASE_API?.JOB}/prod/showJob?id=${jobId}`
      );

      if (error) {
        toast.error(
          `${error.message ?? "Something went Wrong. Please try again"}`
        );
        setErrorScreen(true);
      } else {
        if ([2, 1, 4].includes(data?.data?.status)) {
          navigate("/", { replace: true });
        } else {
          setData(data?.data);
          setLoader(false);
          const srcArr = data?.data?.attachments?.map((attachment, index) => ({
            src: attachment?.src,
          }));
          if (data?.data?.isFile) {
            setFinalPdfLink(srcArr[0]?.src);
            if (data?.data?.attachments[0]?.src) {
              setDisplayFile(true);
              setFileName(
                data?.data?.attachments[0]?.src.substring(
                  data?.data?.attachments[0]?.src?.lastIndexOf("/") + 1
                )
              );
            }
          } else {
            setImageItemArr(srcArr);
          }
        }
      }
    };

    if (jobId) {
      initalFetch();
    }
  }, []);

  const onSortEnd = (e) => {
    var newImageItemArr = arrayMoveImmutable(
      imageItemArr,
      e.oldIndex,
      e.newIndex
    );
    setImageItemArr(newImageItemArr);
  };

  // remove images
  const onRemove = (index) => {
    imageItemArr.splice(index, 1);
    setState(!state);
  };

  // handle file change pdf and doc
  const handleFileChange = (e) => {
    setOpenMenu(false);

    // fetch extension
    const ext = fileExtension(e.target.files[0]);

    if (e.target.files && e.target.files[0].size < 20971520) {
      if (ext === data?.fileExt) {
        setDisplayFile(true);
        setFileName(e.target.files[0].name);
        setSelectedFiles(e.target.files[0]);
        uploadFileHandler(e.target.files[0]);
      } else if (
        (ext === "doc" || ext === "docx") &&
        data?.fileExt === "word"
      ) {
        setDisplayFile(true);
        setFileName(e.target.files[0].name);
        setSelectedFiles(e.target.files[0]);
        uploadFileHandler(e.target.files[0]);
      } else {
        toast.error(`Please select a ${data?.fileExt} file`);
      }
    } else {
      toast.error(`Please select file size below 20MB`);
    }
  };

  // pdf doc api integretion for gcc
  const uploadFileHandler = async (file) => {
    const fileExt = fileExtension(file);
    const { data: urlData, error: urlError } = await API.post(
      `${BASE_API?.GCS}/prod/getSignedUrlGCS`,
      {
        key: `${CurrentUser?.getId()}/${data?.jobUUID}/${`FILE_${
          Date.now() + "." + fileExt
        }`}`,
      }
    );

    if (urlError) {
      toast.error(urlError.message ?? "pdf upload error");
    } else {
      const url = urlData?.url;
      uploadToGcs(url, file);
    }
  };

  const uploadToGcs = (url, file) => {
    var instance = axios.create({});
    delete instance.defaults.headers.common["Authorization"];
    delete instance.defaults.headers.put["Content-Type"];

    instance
      .put(url, file, {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgressPersent(percentCompleted);
        },
      })
      .then((_) => {
        const uploadedUrl = url?.split("?")[0];
        setFinalPdfLink(uploadedUrl);
      })
      .catch((error) => {
        toast.error("file upload failed");
      });
  };

  // remove file
  const removeFile = () => {
    setSelectedFiles(null);
    setFinalPdfLink(null);
    setDisplayFile(false);
    setPdfPages("");
  };

  // on selecting the image to be edited
  const onChangeFileSelect = (e) => {
    setOpenMenu(false);
    setArrLoad(true);

    // only img allowed
    var imageWord = e.target.files[0].type.substring(0, 5);
    // only images less than 20mb are allowed
    if (
      e.target.files &&
      e.target.files.length > 0 &&
      imageWord === "image" &&
      e.target.files[0].size < 20000000
    ) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      setOpenEditorModal(true);
    } else {
      toast.error("Please select a smaller size image");
    }
  };

  //clear input value if same file is choosen again
  const onClickFileSelect = (e) => {
    e.target.value = "";
  };

  const ctaAddOnClick = () => {
    setOpenMenu(true);
  };

  // draft job handler
  const draftJobHandler = async (rename) => {
    if (draftId || rename) {
      setDraftPopUpPrimaryCtaLoading(true);
      const fetchedValues = {
        isFile: data?.isFile,
        status: "draft",
        attachments: !!data?.isFile
          ? !finalPdfLink
            ? []
            : [{ src: finalPdfLink }]
          : imageItemArr,
        jobUUID: data?.jobUUID,
      };
      const endpoint = `${BASE_API?.JOB}/prod/updateJob/?id=${jobId}`;

      const { error, data: resultData } = await API.post(
        endpoint,
        fetchedValues
      );
      setDraftPopUpPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `${error.message ?? "Something went Wrong. Please try again"}`
        );
      } else {
        if (!rename) {
          toast.success("File saved as Draft");
        }
        if (rename) {
          navigate("/new-job", {
            state: { id: data?.id, name: data?.name },
          });
        }
        setDraftId(false);
      }
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  // inprogress handler
  const inProgressJobHandler = async () => {
    if (inProgressId) {
      setInProgressPopUpPrimaryCtaLoading(true);
      const fetchedValues = {
        isFile: data?.isFile,
        status: "inProgress",
        attachments: !!data?.isFile ? [{ src: finalPdfLink }] : imageItemArr,
        jobUUID: data?.jobUUID,
      };
      const endpoint = `${BASE_API?.JOB}/prod/updateJob/?id=${jobId}`;

      const { error, data: resultData } = await API.post(
        endpoint,
        fetchedValues
      );
      setInProgressPopUpPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `${error.message ?? "Something went Wrong. Please try again"}`
        );
      } else {
        toast.success(`File successfully sent for audio processing.`);
        navigate("/success", { replace: true });
      }
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  // edit menu open
  const ctaEditMenuHandler = (menuItem) => {
    switch (menuItem?.cta) {
      case "rename":
        draftJobHandler(true);
        break;
      case "discard":
        setDeleteId(jobId);
        setEditMenu(false);
        break;
      case "draft":
        setDraftId(jobId);
        setEditMenu(false);
        break;
      default:
        return null;
    }
  };

  // delete job
  const discardJobHandler = async () => {
    if (deleteId) {
      setDeletePopUpPrimaryCtaLoading(true);
      const endpoint = `${BASE_API?.JOB}/prod/deleteJob/?id=${jobId}`;

      const { error } = await API.post(endpoint);
      setDeletePopUpPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `${error.message ?? "Something went Wrong. Please try again"}`
        );
      } else {
        toast.success(`File Deleted Sucessfully.`);
        navigate("/", { replace: true });
        setDeleteId(false);
      }
    } else {
      toast.error("Somthing went wrong. Please try again.");
    }
  };

  // discard job
  const deletePopUpConfig = {
    primaryCta: {
      value: "Proceed",
      loading: deletePopUpPrimaryCtaLoading,
      onClick: discardJobHandler,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  // draft job
  const draftPopUpConfig = {
    primaryCta: {
      value: "Proceed",
      loading: draftPopUpPrimaryCtaLoading,
      onClick: draftJobHandler,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDraftId,
    },
    open: { value: draftId },
    icon: { value: <ExclamationIcon /> },
  };

  // inProgress job
  const inProgressPopUpConfig = {
    primaryCta: {
      value: "Proceed",
      loading: inProgressPopUpPrimaryCtaLoading,
      onClick: inProgressJobHandler,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setInProgressId,
    },
    open: { value: inProgressId },
    icon: { value: <CheckIcon /> },
  };

  return (
    <>
      <>
        {!!openEditorModal ? (
          <ImageEditor
            openEditorModal={openEditorModal}
            setOpenEditorModal={setOpenEditorModal}
            imgSrc={imgSrc}
            setImgSrc={setImgSrc}
            crop={crop}
            setCrop={setCrop}
            finalImg={finalImg}
            setFinalImg={setFinalImg}
            finalFile={finalFile}
            setFinalFile={setFinalFile}
            jobUUID={data?.jobUUID}
          />
        ) : null}
      </>

      {/* delete popup component  function */}
      <PopUpWrapper
        fullTitle="Discard File"
        desc="Do you want to delete this file?"
        config={deletePopUpConfig}
      />

      {/* draft popup component  function */}
      <PopUpWrapper
        fullTitle="Draft File"
        desc="Do you want to save this file as draft?"
        config={draftPopUpConfig}
      />

      {/* inprogress popup component  function */}
      <PopUpWrapper
        fullTitle="Process to Audio"
        desc="Do you want to process this file to audio?"
        config={inProgressPopUpConfig}
      />

      <div className="bg-light-gray font-grotesk relative min-h-screen pb-4">
        <div className="bg-light-gray sticky top-0 z-10 py-2 ">
          <Nav
            ctaLeft={<ArrowLeftIcon className="my-0.5 h-6 w-6" />}
            ctaLeftOnClick={() => {
              const res = window.confirm(
                "Are you sure you want to leave. Any unsaved changes will be lost?"
              );
              if (!!res) {
                navigate("/");
              }
            }}
            ctaRight={
              <>
                {!!editMenu || !!openMenu ? (
                  <img src="/assets/images/icons/crossIcon.svg" alt="close" />
                ) : null}
                {!!errorScreen || !!openMenu ? null : !!editMenu ? null : (
                  <div
                    className="mt-0.5"
                    onClick={() => {
                      if (!errorScreen) {
                        setEditMenu(!editMenu);
                      }
                    }}
                  >
                    <img
                      src="/assets/images/icons/threeDotMenu.svg"
                      alt="hamburger"
                    />
                  </div>
                )}
              </>
            }
            ctaRightOnClick={() => {
              setOpenMenu(false);
            }}
          />

          {/* Title and toggle block */}
          {!!loader || !!errorScreen ? null : (
            <div className="mx-5">
              <h1 className="text-2xl font-bold">{data?.name}</h1>
              {imageItemArr.length > 1 ? (
                <>
                  <div className="flex flex-row justify-end ">
                    <Toggle
                      title="rearrange :"
                      enabled={drag}
                      setEnabled={setDrag}
                    />
                  </div>
                </>
              ) : null}
            </div>
          )}
        </div>

        <Spacer height={10} />
        {loader ? (
          <div
            className={classNames(
              "font-grotesk flex items-center justify-center"
            )}
          >
            {!!errorScreen ? (
              <InfoMessage
                title="Somthing went wrong"
                description="We’re facing some technical issue, please check back after sometime or refresh the page."
                ctaTitle="Try Again"
              />
            ) : (
              <Loader title={`Fetching file details ...`} />
            )}
          </div>
        ) : (
          <div>
            <div className="mx-5">
              <Spacer height={10} />
              {data?.isFile ? (
                <Upload
                  ctaAddOnClick={ctaAddOnClick}
                  fileName={fileName}
                  displayFile={displayFile}
                  removeFile={removeFile}
                  filetype={data?.fileExt}
                  finalPdfLink={finalPdfLink}
                  pdfPages={pdfPages}
                  setPdfPages={setPdfPages}
                />
              ) : (
                <div className="">
                  {drag ? (
                    <SortableList
                      items={imageItemArr}
                      onSortEnd={onSortEnd}
                      ctaAddOnClick={ctaAddOnClick}
                      onRemove={onRemove}
                      dragIcon={true}
                      useWindowAsScrollContainer
                    />
                  ) : (
                    <UploadImages2
                      items={imageItemArr}
                      onSortEnd={onSortEnd}
                      ctaAddOnClick={ctaAddOnClick}
                      onRemove={onRemove}
                      onEdit={ctaAddOnClick}
                      removeIcon={true}
                      displayFileName={true}
                    />
                  )}
                </div>
              )}
            </div>

            {/* for image audio convert */}
            <div
              className={classNames(
                imageItemArr.length > 0 || displayFile
                  ? "fixed inset-x-0 bottom-2 flex w-full flex-col items-center justify-around "
                  : "hidden"
              )}
            >
              <Button
                type="submit"
                size="lg"
                className=" h-16 w-10/12 px-2 uppercase "
                onClick={() => setInProgressId(data?.id)}
              >
                Process to Audio
              </Button>
            </div>

            <Drawer
              menuItems={!data?.isFile ? IMAGE_MENU : FILE_MENU}
              menuTitle="Add new page"
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
              onClickFileSelect={onClickFileSelect}
              onChangeFileSelect={
                !data?.isFile ? onChangeFileSelect : handleFileChange
              }
              activateInput={true}
            />

            {/* for edit */}
            <Drawer
              menuItems={FILE_MODIFY_MENU}
              menuTitle={data?.name}
              openMenu={!!editMenu}
              setOpenMenu={setEditMenu}
              ctaMenuOnClickHandler={ctaEditMenuHandler}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AddFile;
