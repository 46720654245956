import React from "react";
import { navigate } from "@reach/router";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import Nav from "../stories/navs/Nav";

const Pricing = () => {
  return (
    <div className="bg-light-gray font-grotesk min-h-screen py-4">
      <Nav
        ctaLeft={<ArrowLeftIcon className="my-0.5 h-6 w-6" />}
        ctaLeftOnClick={() => {
          navigate(-1);
        }}
        heading="Pricing"
      />
      <div className="mx-5 flex flex-col items-start justify-start space-y-2 pt-2">
        <h2 className="text text-lg font-semibold text-black">
          Charging for the App
        </h2>
        <p className=" text-justify text-base font-normal leading-tight text-black">
          After the initial testing phase is over, it is our intention to charge
          a small annual fee for the App. The App will be free to download. The
          first 5 documents, sent for audio, will be free. Thereafter, will be
          charging users $15 per year for the App. This amount will increase
          over time.
        </p>
        <p className="text-justify text-base leading-tight text-black">
          The reason why we have to charge for the App is because, having an App
          is like having a garden: we have to continuously maintain it. It takes
          a lot of technical resource to keep a complicated App such as this
          updated.
        </p>
        <p className="text-justify text-base leading-tight text-black">
          We thank you for your ongoing support for this App.
        </p>
      </div>
    </div>
  );
};

export default Pricing;
