import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

const Drawer = ({
  menuTitle,
  openMenu,
  setOpenMenu,
  menuItems,
  ctaMenuOnClickHandler,
  onClickFileSelect,
  onChangeFileSelect,
  activateInput = false,
}) => {
  let refDiv = useRef(null);
  return (
    <>
      <Transition.Root show={!!openMenu} as={Fragment}>
        <Dialog
          initialFocus={refDiv}
          as="div"
          className="fixed inset-0 overflow-hidden"
          onClose={setOpenMenu}
        >
          <div ref={refDiv} className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="pointer-events-none fixed  inset-x-0 bottom-0 flex h-auto max-w-full ">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-700"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-300 sm:duration-700"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full"
              >
                <div className="pointer-events-auto  w-screen max-w-full">
                  <div className="flex h-full flex-col overflow-y-scroll rounded-t-3xl  bg-black  pb-6 pt-3  shadow-xl">
                    <div className="px-9 sm:px-6">
                      <div className="relative flex items-end justify-center pb-3 text-white">
                        <div className="h-1.5 w-12 rounded-full bg-white"></div>
                      </div>
                      <div className="font-grotesk flex items-start justify-between">
                        <Dialog.Title className="text-xl font-bold text-white">
                          {menuTitle ?? "Menu"}
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-9 sm:px-6">
                      <ul>
                        {menuItems?.map((menuItem) => (
                          <li
                            onClick={
                              activateInput
                                ? null
                                : () => {
                                  ctaMenuOnClickHandler(menuItem);
                                }
                            }
                            key={menuItem.name}
                            className="cursor-pointer active:bg-neutral-900"
                          >
                            <label
                              htmlFor={menuItem?.cta}
                              className="flex cursor-pointer items-center py-2 active:bg-neutral-900"
                            >
                              <img
                                className="h-16 w-16 "
                                src={menuItem.image}
                                alt=""
                              />
                              <div className="ml-3">
                                <p className="font-grotesk text-xl font-bold text-white">
                                  {menuItem.name}
                                </p>
                              </div>
                            </label>
                            {activateInput ? (
                              <input
                                type="file"
                                className="hidden"
                                id={menuItem?.cta}
                                accept={menuItem?.accept}
                                onChange={onChangeFileSelect}
                                onClick={onClickFileSelect}
                                capture={menuItem?.capture}
                              />
                            ) : null}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default Drawer;
