import React, { useRef, useState } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const SoundPlayer = ({ audioSrcFile, autoPlay }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  return (
    <div className="bg-light-gray font-grotesk ">
      <div className="flex flex-row  items-center justify-center">
        <div
          className=" h-14 w-14 flex-col items-center  justify-center  "
          onClick={() => {
            if (isPlaying) {
              audioRef.current.audio.current.pause();
            } else {
              audioRef.current.audio.current.play();
            }
          }}
        >
          <img
            className=" active:rounded-full  active:ring-2 active:ring-neutral-300"
            src={
              isPlaying
                ? "/assets/images/icons/pauseIcon.svg"
                : "/assets/images/icons/playIcon.svg"
            }
            alt=""
          />
        </div>
        <AudioPlayer
          autoPlay={autoPlay ?? true}
          src={
            audioSrcFile ??
            "http://res.cloudinary.com/alick/video/upload/v1502689683/Luis_Fonsi_-_Despacito_ft._Daddy_Yankee_uyvqw9.mp3"
          }
          onPlay={(e) => {
            setIsPlaying(true);
          }}
          onPause={(e) => {
            setIsPlaying(false);
          }}
          showSkipControls={false}
          showJumpControls={false}
          customVolumeControls={[RHAP_UI.MAIN_CONTROLS]}
          customAdditionalControls={[]}
          customProgressBarSection={[
            // <div>/</div>,
            // RHAP_UI.PLAY,
            RHAP_UI.PROGRESS_BAR,
            // RHAP_UI.CURRENT_TIME,
            // RHAP_UI.DURATION,
            RHAP_UI.CURRENT_LEFT_TIME,
          ]}
          ref={audioRef}
        />
      </div>
    </div>
  );
};

export default SoundPlayer;
