import classNames from "classnames";
export default function Loader({ className, title }) {
  return (
    <div>
      <div className="flex justify-center">
        <svg
          className={classNames(
            "-ml-1 mr-3 h-5 w-5 animate-spin ",
            className || "text-gray-900"
          )}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          width="26"
          height="26"
          viewBox="0 0 26 26"
        >
          <g
            transform="translate(1 1)"
            stroke="currentColor"
            strokeWidth="1.5"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
          >
            <circle opacity=".3" cx="12" cy="12" r="12" />
            <path
              d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
              strokeDasharray="20"
            />
          </g>
        </svg>
      </div>
      <p className="mt-2 text-lg font-medium text-gray-900">{title}</p>
    </div>
  );
}
