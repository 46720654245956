import React, { useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { Link } from "@reach/router";
import SoundPlayer from "./SoundPlayer";
import { formatTime } from "../utils/helper";
import ProcessingLoader from "./loaders/ProcessingLoader";

const File = ({ ctaOnClick, fileItems }) => {
  const [playing, setPlaying] = useState("");

  const statusIconFunction = (status) => {
    switch (status) {
      case 0:
        return "/assets/images/icons/draft.svg";
      case 1:
        return "/assets/images/icons/processing.svg";
      case 2:
        return "/assets/images/icons/playIcon.svg";
      case 4:
        return "/assets/images/icons/error.svg";
      default:
        return "/assets/images/icons/error.svg";
    }
  };

  const statusTextFunction = (status) => {
    switch (status) {
      case 0:
        return "draft";
      case 1:
        return "processing";
      case 2:
        return "play";
      case 4:
        return "error";
      default:
        return "error";
    }
  };
  return (
    <div>
      <ul>
        {fileItems?.map((fileItem) => (
          <li key={`${fileItem.id}`} className="py-2 ">
            <div className="flex space-x-2">
              {playing === fileItem?.id ? (
                <div className="-mx-1"> </div>
              ) : (
                <div
                  className="flex flex-col items-center justify-center"
                  onClick={() => {
                    if (fileItem?.status === 2) {
                      ctaOnClick(fileItem, setPlaying);
                    }
                  }}
                >
                  {fileItem?.status === 1 ? (
                    <ProcessingLoader />
                  ) : (
                    <img
                      className="h-[3.125rem] w-[3.125rem] rounded-full p-px"
                      src={statusIconFunction(fileItem?.status)}
                      alt="status"
                    />
                  )}
                  <p
                    className={classNames(
                      "text-tiny-xs py-0.5 font-medium uppercase tracking-wide",
                      fileItem?.status === 4 ? "text-red-500" : "text-[#01221d]"
                    )}
                  >
                    {statusTextFunction(fileItem?.status) === "play"
                      ? fileItem?.audioDuration
                        ? formatTime(fileItem?.audioDuration)
                        : "play"
                      : statusTextFunction(fileItem?.status)}
                  </p>
                </div>
              )}
              <div className="border-b-0.5 flex-1  space-y-1 border-neutral-200">
                <div className="flex items-center justify-between ">
                  <div className="w-3/4">
                    <h3 className=" leading-3.5 pt-1 text-sm font-semibold capitalize">
                      {fileItem?.name}
                    </h3>
                    <div className="mt-0.5 flex flex-col justify-start gap-x-2">
                      <p className="text-small-xs pt-1 font-bold uppercase text-neutral-500">
                        {moment(fileItem?.createdAt).format("LL")}
                      </p>
                      {/* status info label  */}
                      {fileItem?.status === 0 &&
                      !!(fileItem?.attachments.length > 0) ? (
                        <p className="text-small-xs pt-1 font-semibold text-[#5468ff]">
                          Click on the arrow to process the file
                        </p>
                      ) : fileItem?.status === 1 &&
                        !!(fileItem?.attachments.length > 0) ? (
                        <p className="text-small-xs pt-1 font-semibold text-neutral-700">
                          We are working on it.
                        </p>
                      ) : null}
                      {/*  */}
                    </div>
                  </div>
                  <Link
                    to={
                      fileItem?.status === 0
                        ? `add-file/${fileItem?.id}`
                        : `job-details/${fileItem?.id}`
                    }
                  >
                    <div className="flex flex-row items-center justify-evenly space-x-1">
                      {fileItem?.isFile ? (
                        <div className="flex h-12 w-12 items-center justify-center ">
                          <div className="h-12 w-12 overflow-hidden rounded-md">
                            {fileItem?.fileExt === "word" ? (
                              !!fileItem?.attachments[0]?.src ? (
                                <div className="h-12 w-12 bg-white ">
                                  <img
                                    src="/assets/images/icons/wordIcon.svg"
                                    alt="doc-file"
                                  />
                                </div>
                              ) : null
                            ) : !!fileItem?.attachments[0]?.src ? (
                              <div className="h-12 w-12 bg-white">
                                <img
                                  src="/assets/images/icons/pdfIcon.svg"
                                  alt="pdf-file"
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : !!fileItem?.attachments[0]?.src ? (
                        <img
                          className="h-12 w-12 rounded-md border-0 bg-gray-50 object-contain "
                          src={fileItem?.attachments[0]?.src}
                          alt="img"
                        />
                      ) : null}

                      <img
                        className="h-11 w-4"
                        src="/assets/images/icons/rightArrow.png"
                        alt=""
                      />
                    </div>
                  </Link>
                </div>
                {playing === fileItem?.id && (
                  <SoundPlayer audioSrcFile={fileItem?.audioFileUrl} />
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default File;
