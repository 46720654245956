import React from "react";
import { SortableElement } from "react-sortable-hoc";
import UploadImageItem from "./UploadImageItem";

const SortableItem = SortableElement(UploadImageItem);

export const UploadImages = ({ ctaAddOnClick, items, onRemove, dragIcon }) => {
  return (
    <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      {items.map((x, index) => {
        return (
          <SortableItem
            todo={x}
            index={index}
            key={index}
            onRemove={onRemove}
            dragIcon={dragIcon}
          />
        );
      })}
      <li className="relative drop-shadow-xl">
        <div
          onClick={ctaAddOnClick}
          className="group xxs:h-52 xxs:w-40 relative flex aspect-[10/7] h-44 w-32 items-center justify-center rounded-lg bg-white"
        >
          <img
            src="/assets/images/icons/addIcon.svg"
            alt=""
            className="pointer-events-none h-14 w-14 object-cover "
          />
        </div>
        <p className="font-grotesk text-dark-teal xxs:w-40 my-2 w-32 text-center text-xs font-medium">
          Add Image
        </p>
      </li>
    </ul>
  );
};

export const UploadImages2 = ({
  ctaAddOnClick,
  items,
  onRemove,
  removeIcon,
  onEdit,
  displayFileName,
}) => {
  return (
    <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      {items.map((x, index) => {
        return (
          <UploadImageItem
            todo={x}
            index={index}
            key={index}
            onRemove={onRemove}
            removeIcon={removeIcon}
            onEdit={onEdit}
            displayFileName={displayFileName}
          />
        );
      })}
      <div className="relative drop-shadow-xl">
        <div
          onClick={ctaAddOnClick}
          className="group xxs:h-52 xxs:w-40 relative flex aspect-[10/7] h-44 w-32 items-center justify-center overflow-hidden rounded-lg bg-white"
        >
          <img
            src="/assets/images/icons/addIcon.svg"
            alt=""
            className="pointer-events-none h-14 w-14 object-cover "
          />
        </div>
        <p className="font-grotesk text-dark-teal xxs:w-40 my-2 w-32 text-center text-xs font-medium uppercase">
          Add Image
        </p>
      </div>
    </div>
  );
};
