/* Collection of Authentication-Authorization related utility functions */
import { toast } from "../../../stories/toast/Toast";
import { API } from "../API";

const getCurrentUserDetails = () => {
  const curUserDetail = localStorage.getItem("current_user_details");
  if (curUserDetail) return JSON.parse(curUserDetail);
  return null;
};

export const CurrentUser = {
  setToken: (token) => localStorage.setItem("user_auth_token", token),
  getToken: () => localStorage.getItem("user_auth_token"),
  logout: async () => {
    localStorage.removeItem("user_auth_token");
    localStorage.removeItem("current_user_details");
    toast.success("Logging out...");
    setTimeout(() => {
      window.location.href = "/auth/login";
    }, 500);
  },
  isLoggedIn: () => !!localStorage.getItem("user_auth_token"),
  getDetails: () => getCurrentUserDetails(),

  getId: () => getCurrentUserDetails().user?.id,
  setDetails: (data) => {
    localStorage.setItem("current_user_details", data);
  },
  goToProfile: null,
};

export const logout = async () => {
  localStorage.removeItem("current_user_details");
  localStorage.removeItem("user_auth_token");
  toast.success("Logout successful");
  setTimeout(() => {
    window.location.href = "/auth/login";
  }, 1500);
};
